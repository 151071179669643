/**
 * Màn hình danh sách người dùng
 */
import { memo } from "react";
import { Layout, Typography } from "antd";
import { Space } from "antd";
import _ from "lodash";
import ListService from "pages/Service/ListService/index";
const { Title } = Typography;
const { Content } = Layout;
function Service() {
  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between ">
          <Title style={{ marginBottom: 0 }} level={4}>
            Quản trị gói thuê bao
          </Title>
        </div>
        <ListService />
      </Space>
    </Content>
  );
}

export default memo(Service);
