/**
 * Màn hình quản trị admin
 */
import { memo } from "react";
import { Layout, Typography, Space } from "antd";
import _ from "lodash";
import ListAdmin from "pages/AdminUser/ListUser";
const { Title } = Typography;
const { Content } = Layout;
function AdminUser() {
  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between">
          <Title level={4}>Quản trị tài khoản Admin</Title>
          <Space direction="horizontal" size={"middle"}></Space>
        </div>
        <ListAdmin />
      </Space>
    </Content>
  );
}

export default memo(AdminUser);
