import { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Table,
  Typography,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { getUser } from "features/userSlice";
import { PayloadType, Role, SelectOptionType, UserType } from "type";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "utils";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { getSubscribers } from "features/subscriberSlice";
import _, { set } from "lodash";
import moment from "moment";
import { getProvinces } from "features/provinceSlice";
import { FULL_DATE_FORMAT } from "const";
import { getHerds } from "features/herdSlice";
interface DataType {
  phone: string;
  name: string;
  nameForPig: string;
  farmName: string;
  farmTypeId: string;
  farmScaleId: string;
  email: string;
  personInChargeId: string;
  address: string;
  provinceId: string;
  servicePackageId: string;
  serviceTimeId: string;
}
interface UserInfo {
  idUpdate: string | undefined;
  record: DataType;
}
interface ColumnType {
  day: string;
  type: string;
  point: string;
  time: string;
}
const { Title } = Typography;
function RUser({ record }: any) {
  const dispatch = useDispatch();
  const [dataFirstHerd, setDataFirstHerd] = useState<string[]>([]);

  //lấy ra thông tin trại được tạo với người dùng
  const getHerdType = (userId: string) => {
    const payload: PayloadType = {
      query: queryString.stringify({
        level: 0,
        sortBy: "createdAt:desc",
        userId: userId,
        populate: "farmScaleId, herdStatId, typeId",
      }),
      skipUpdateReducer: true,
      callback: {
        success(listHerdTypes) {
          const results = _.get(listHerdTypes, "results", []);
          if (!!results && Array.isArray(results)) {
            setDataFirstHerd(results[0]);
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerds(payload));
  };
  useEffect(() => {
    getHerdType(record?.id);
  }, [record]);

  return (
    <>
      <div className="ant-modal-body">
        <Row>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Mã tài khoản "
              value={_.get(record, "code", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tên đăng nhập "
              value={_.get(record, "phone", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Họ tên chủ trại "
              value={_.get(record, "name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Email "
              value={_.get(record, "email", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Người phụ trách "
              value={_.get(record, "personInChargeId.name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Thiết lập tên gọi "
              value={_.get(dataFirstHerd, "nameForPig", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Địa chỉ "
              value={_.get(record, "address", "")}
            />
          </Col>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tên trang trại "
              value={_.get(dataFirstHerd, "name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Phân loại trang trại "
              value={_.get(dataFirstHerd, "typeId.name", "")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Quy mô trang trại "
              value={_.get(dataFirstHerd, "farmScaleId.name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tỉnh/TP"
              value={_.get(dataFirstHerd, "province", "")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Quận/ Huyện"
              value={_.get(dataFirstHerd, "district", "")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Trạng thái  "
              value={
                _.get(record, "status") === "active" ? "Hoạt động" : "Khóa"
              }
            />
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Quay lại
        </Button>
      </div>
    </>
  );
}

export default RUser;
