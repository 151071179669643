/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { ProductTemp } from "type";

/*
 * Declare type of data
 */
interface ProductTempState {
  productTemps: ProductTemp[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: ProductTempState = {
  productTemps: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager product Temp
 **/
export const productTempSlice: any = createSlice({
  name: "productTempSlice",
  initialState: initialState,
  reducers: {
    getProductTemps: (state, action) => {
      state.isLoading = true;
    },
    getProductTempsSuccess: (state, action) => {
      state.productTemps = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createProductTemps: (state, action) => {
      state.isLoading = true;
    },
    updateProductTemps: (state, action) => {
      state.isLoading = true;
    },
    deleteProductTemps: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createProductTemps,
  updateProductTemps,
  deleteProductTemps,
  getProductTemps,
  getProductTempsSuccess,
  setQuery,
} = productTempSlice.actions;
export default productTempSlice.reducer;
