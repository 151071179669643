import { useEffect } from "react";
import { Button, Col, Row, Table, Typography, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { setModal } from "features/modalSlice";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { PayloadType } from "type";
import { closeModal } from "utils";
import queryString from "query-string";
import _ from "lodash";
import { getHerdTypes } from "features/herdTypeSlice";
import { getSharedDatas } from "features/sharedDataSlice";

const { Title } = Typography;
function RProcess({ record }: any) {
  const [tanSuat, setTanSuat] = useState<any[]>([]);
  const [addDatas, setAddDatas] = useState<any[]>([]);
  const [donVi, setDonVi] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [listHerdType, setListHerdType] = useState<any[]>([]);

  const columns: ColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "timeUnitId",
      key: "timeUnitId",
      width: "15%",
    },
    {
      title: "Giá trị",
      dataIndex: "timeValue",
      key: "timeValue",
      width: "15%",
    },
    {
      title: "Tần suất",
      dataIndex: "frequencyId",
      key: "frequencyId",
      width: "15%",
    },
    {
      title: "Nội dung thực hiện",
      dataIndex: "note",
      key: "note",
      width: "55%",
    },
  ];
  const getDataByGroupType = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        populate: "typeGroupId",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            results.forEach((item: any) => {
              if (!!item.typeGroupId && item.typeGroupId.code === "TIME_UNIT") {
                setDonVi((prevData) => [...prevData, item]);
              }
              if (!!item.typeGroupId && item.typeGroupId.code === "FREQUENCY") {
                setTanSuat((prevData) => [...prevData, item]);
              }
            });
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getSharedDatas(payload));
  };
  useEffect(() => {
    getDataByGroupType();
  }, []);

  useEffect(() => {
    const addData = record?.actionIds;
    const data: any = [];
    addData.map((item: any) => {
      const dataAdd = item?.addData;
      const aaaaa = dataAdd.map((itemChild: any) => {
        return {
          [itemChild.code]: itemChild?.param?.value,
        };
      });

      const output = aaaaa.reduce((result: any, item: any) => {
        Object.assign(result, item);
        return result;
      }, {});
      data.push(output);
    });
    setAddDatas(data);
  }, [record]);

  // lấy danh sách loại trại
  const getListHerdType = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(herdType) {
          const results = _.get(herdType, "results", []);
          if (!!results && Array.isArray(results)) {
            setListHerdType(results);
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách loại trại! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerdTypes(payload));
  };
  useEffect(() => {
    getListHerdType();
  }, []);

  const herdType = () => {
    const id = _.get(record, "excutionTagIds[0]", "");
    const data = listHerdType.filter((item: any) => item.id === id);
    return data[0]?.name;
  };

  const excutionTagIds = herdType();
  return (
    <>
      <div className="ant-modal-body">
        <Row>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Mã quy trình"
              value={_.get(record, "code", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tên quy trình"
              value={_.get(record, "name", "")}
            />
          </Col>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Loại trại áp dụng"
              value={
                _.get(record, "appliedTo", "") === "pig" ? "Lợn" : "Chuồng"
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="gutter-row" span={24}>
            <Title level={5} style={{ paddingBottom: "12px", paddingTop: 30 }}>
              Thông tin chi tiết
            </Title>

            <Table
              columns={columns}
              rowKey={"id"}
              dataSource={addDatas}
              pagination={false}
              scroll={{ y: 110 }}
            />
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Quay lại
        </Button>
      </div>
    </>
  );
}

export default RProcess;
