import { useEffect, useState } from "react";
import {
  Popconfirm,
  Space,
  Typography,
  Col,
  Row,
  Select,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { PayloadType, SelectOptionType, ShareDataType } from "type";
import Table, { ColumnsType } from "antd/lib/table";
import CUListDataShared from "pages/ListDataShared/CUListDataShared";
import queryString from "query-string";
import { getTypeGroups } from "features/typeDataSharedSlice";
import {
  deleteSharedData,
  getSharedDatas,
  setQuery,
  setQueryFilter,
} from "features/sharedDataSlice";
import _ from "lodash";
const { Title } = Typography;
function ListDataShared() {
  const dispatch = useDispatch();
  const { query, sharedDatas } = useSelector(
    (state: any) => state.sharedDataReducer
  );

  const [typeGroupOptions, setTypeGroupOptions] = useState<SelectOptionType[]>(
    []
  );

  const [rowUpdate, setRowUpdate] = useState<ShareDataType | null>(null);

  // Lấy options cho ô select nhóm thể loại
  const getFieldTypeShared = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setTypeGroupOptions(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                  ...item,
                };
              })
            );
          }
        },
        failed() {},
      },
    };
    dispatch(getTypeGroups(payload));
  };

  useEffect(() => {
    getFieldTypeShared();
  }, []);

  //desc column
  const columns: ColumnsType<ShareDataType> = [
    {
      title: "Mã số",
      dataIndex: "code",
      key: "code",
      align: "left",
      render(value) {
        return value;
      },
    },
    {
      title: "Nhóm thể loại",
      dataIndex: ["typeGroupId", "name"],
      key: "typeGroupId.name",
      align: "left",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      align: "left",
    },

    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      align: "left",
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record: any) => {
        return (
          <Space>
            <a
              onClick={() => {
                setRowUpdate(record);
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? "";
                if (id) {
                  const payload: PayloadType = {
                    params: id,
                    callback: {
                      success(values) {
                        notification.success({
                          message: "Xoá danh mục dùng chung thành công!",
                        });
                        getListSharedData();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: "Xoá danh mục dùng chung thất bại!",
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(deleteSharedData(payload));
                }
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  // Danh sách danh mục dùng chung
  const getListSharedData = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        populate: "typeGroupId,listOfId",
      }),
      callback: {
        success() {},
        failed() {},
      },
    };
    dispatch(getSharedDatas(payload));
  };

  useEffect(() => {
    getListSharedData();
  }, [query]);

  // hàm filter
  const handleChangeFilterType = (value: string) => {
    dispatch(
      setQueryFilter({
        ...query,
        populate: "typeGroupId", // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        typeGroupId: value, //Trường lọc data là name và
      })
    );
  };

  const handleSearchFilterType = (value: string) => {
    dispatch(
      setQueryFilter({
        ...query,
        page: 1,
        populate: "typeGroupId", // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        typeGroupId: value, //Trường lọc data là name và
      })
    );
  };

  return (
    <Content className="custom-layout-content">
      <Row
        style={{
          height: "70px",
          marginBottom: "16px",
          lineHeight: "70px",
          padding: "16px 24px",
        }}
        className="background-content-layout"
      >
        <Col span={24}>
          <div className="space-between">
            <Title
              level={4}
              style={{
                marginBottom: "0px",
                height: "40px",
                lineHeight: "40px",
              }}
            >
              Danh mục dữ liệu dùng chung
            </Title>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={6}>
          <div
            className="background-content-layout"
            style={{
              padding: "24px",
              marginRight: "8px",
            }}
          >
            <>
              <div className="space-between">
                <Title
                  level={4}
                  style={{
                    marginBottom: "20px",
                    height: "40px",
                    lineHeight: "40px",
                  }}
                >
                  {Object.keys(rowUpdate ?? {}).length === 0
                    ? "Thêm mới"
                    : "Cập nhật"}
                </Title>
              </div>
              <CUListDataShared
                rowUpdate={rowUpdate}
                getListSharedData={getListSharedData}
                setRowUpdate={setRowUpdate}
                typeGroupOptions={typeGroupOptions}
              />
            </>
          </div>
        </Col>
        <Col span={18}>
          <div
            className=" background-content-layout"
            style={{ padding: "24px 16px", marginLeft: "8px" }}
          >
            <div
              className="space-between"
              style={{
                marginBottom: "20px",
                height: "40px",
                lineHeight: "40px",
                paddingLeft: "10px",
              }}
            >
              <Title level={5}>Danh sách</Title>
              <Space className="custom-placeholder-select">
                <Select
                  showSearch
                  style={{ width: "200px" }}
                  placeholder="Nhóm thể loại"
                  options={typeGroupOptions.map((item: SelectOptionType) => {
                    return {
                      label: _.get(item, "name", ""),
                      value: item.value,
                    };
                  })}
                  allowClear
                  onChange={handleChangeFilterType}
                  onSearch={handleSearchFilterType}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Space>
            </div>

            <Table
              style={{ marginLeft: "8px" }}
              className=""
              rowKey={"id"}
              columns={columns}
              dataSource={_.get(sharedDatas, "results", [])}
              pagination={{
                current: _.get(sharedDatas, "page", 1),
                pageSize: _.get(sharedDatas, "limit", 1),
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                total: _.get(sharedDatas, "totalResults", 1),
                showTotal: (total, range) => {
                  return `Có tất cả ${total} bản ghi`;
                },
                onChange(page, pageSize) {
                  dispatch(
                    setQuery({
                      ...query,
                      page: page,
                      limit: pageSize,
                    })
                  );
                },
              }}
            />
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default ListDataShared;
