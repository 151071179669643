/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { notificationTemplatesType, UserType } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  users: UserType[]; //
  user: UserType | null;
  notificationTemplates: notificationTemplatesType[] | null;
  roles: any;
  query: any; //
  filters: any;
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  users: [],
  notificationTemplates: [],
  user: null,
  roles: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
  filters: null,
};

/**
 *  Slice manager user
 **/
export const userSlice: any = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    getUsers: (state, action) => {
      state.isLoading = true;
    },
    getUser: (state, action) => {
      state.isLoading = true;
    },
    getNotificationTemplates: (state, action) => {
      state.isLoading = true;
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
      state.isLoading = false;
    },
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    getNotificationTemplatesSuccess: (state, action) => {
      state.notificationTemplates = action.payload;
      state.isLoading = false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    setStateUser: (state: any, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
      state.isLoading = false;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
      state.isLoading = false;
    },
    createUser: (state, action) => {
      state.isLoading = true;
    },
    seandNotification: (state, action) => {
      state.isLoading = true;
    },
    updateUser: (state, action) => {
      state.isLoading = true;
    },
    deleteUser: (state, action) => {
      state.isLoading = true;
    },
    setPassByAdmin: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  getUsers,
  getUser,
  setUser,
  updateUser,
  getUsersSuccess,
  getNotificationTemplatesSuccess,
  getNotificationTemplates,
  seandNotification,
  getUserSuccess,
  setQuery,
  createUser,
  deleteUser,
  setStateUser,
  setPassByAdmin,
} = userSlice.actions;
export default userSlice.reducer;
