import axios from "axios";
import queryString from "query-string";
import { REACT_APP_BASE_GRAPHQL_URL } from "./ServiceURL";
import _ from "lodash";

const axiosGraphqlClient = axios.create({
  baseURL: REACT_APP_BASE_GRAPHQL_URL,
  timeout: 5 * 60 * 1000,
  paramsSerializer: {
    serialize: (params) => queryString.stringify(params, { sort: false }),
  },
});

axiosGraphqlClient.interceptors.request.use(
  async (config) => {
    const token = JSON.parse(localStorage.getItem("tokens") || "{}");
    config.headers.authorization = `${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosGraphqlClient.interceptors.response.use(
  function (response) {
    if (response) return response;
  },
  async function (error) {
    // const { config } = error;

    // const refreshtoken = localStorage.getItem('refreshtoken');
    // let token = localStorage.getItem('token');

    // if (
    //   error?.response?.status === 401 &&
    //   !!refreshtoken &&
    //   !!token &&
    //   !config.url.includes('admin') &&
    //   (config.retry || 0) < 4
    // ) {
    //   config.retry = config.retry ? config.retry + 1 : 1;

    //   const data = refreshToken();
    //   token = typeof data === 'string' ? data : await data;

    //   // setting updated token
    //   if (token) {
    //     localStorage.setItem('token', token);

    //     return axiosGraphqlClient(config);
    //   }
    // }
    return Promise.reject(error);
  }
);

export { axiosGraphqlClient };
