/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { AwardType } from "type";

/*
 * Declare type of data
 */
interface AwardState {
  award: AwardType | null;
  awards: AwardType[];
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AwardState = {
  awards: [],
  award: null,
  isLoading: false,
};

/**
 *  Slice manager award
 **/
export const awardSlice = createSlice({
  name: "awardSlice",
  initialState: initialState,
  reducers: {
    getAwards: (state, action) => {
      state.isLoading = true;
    },
    getAwardsSuccess: (state, action) => {
      state.awards = action.payload;
      state.isLoading = false;
    },
    getAward: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const { getAwards, getAwardsSuccess, getAward } = awardSlice.actions;
export default awardSlice.reducer;
