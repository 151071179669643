/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { MangerHerdType } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  herdTypes: MangerHerdType[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  herdTypes: [],
  query: {
    page: 1,
    limit: 10,
  },
  isLoading: false,
};

/**
 *  Slice manager herd type
 **/

export const herdTypeSlice: any = createSlice({
  name: "herdTypeSlice",
  initialState: initialState,
  reducers: {
    getHerdTypes: (state, action) => {
      state.isLoading = true;
    },
    getHerdTypesSuccess: (state, action) => {
      state.herdTypes = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createHerdTypes: (state, action) => {
      state.isLoading = true;
    },
    updateHerdTypes: (state, action) => {
      state.isLoading = true;
    },
    deleteHerdTypes: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createHerdTypes,
  updateHerdTypes,
  deleteHerdTypes,
  getHerdTypes,
  getHerdTypesSuccess,
  setQuery,
} = herdTypeSlice.actions;
export default herdTypeSlice.reducer;
