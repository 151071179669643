/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { StatisticalType } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  statisticals: StatisticalType[]; //
  statisticalByHerd: StatisticalType[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  statisticals: [],
  statisticalByHerd: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 3,
  },
};

/**
 *  Slice manager parameters
 **/
export const statisticalSlice: any = createSlice({
  name: "statisticalSlice",
  initialState: initialState,
  reducers: {
    getStatisticals: (state, action) => {
      state.isLoading = true;
    },

    getStatisticalByHerd: (state, action) => {
      state.isLoading = true;
    },

    getStatisticalGuest: (state, action) => {
      state.isLoading = true;
    },

    getStatisticalsSuccess: (state, action) => {
      state.statisticals = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createStatistical: (state, action) => {
      state.isLoading = true;
    },
    updateStatistical: (state, action) => {
      state.isLoading = true;
    },
    deleteStatistical: (state, action) => {
      state.isLoading = true;
    },

    runReworkSynthesisReports: (state, action) => {},
  },
});
export const {
  createStatistical,
  updateStatistical,
  deleteStatistical,
  getStatisticals,
  getStatisticalByHerd,
  getStatisticalGuest,
  getStatisticalSuccess,
  setQuery,
  runReworkSynthesisReports,
} = statisticalSlice.actions;
export default statisticalSlice.reducer;
