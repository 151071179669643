/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { DataTypeSubscriber } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  subscribers: DataTypeSubscriber[]; //
  subscriber: DataTypeSubscriber | null;
  roles: Array<string>;
  query: any; //
  isLoading: boolean;
  filters: any;
  user: any;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  subscribers: [],
  subscriber: null,
  isLoading: false,
  roles: [],
  query: {
    page: 1,
    limit: 10,
    populate: "farmTypeId, farmScaleId, personInChargeId, techSupporter ",
     sortBy: "createdAt:desc",
  },
  filters: null,
  user: {},
};

/**
 *  Slice manager subscriber
 **/
export const subscriberSlice: any = createSlice({
  name: "subscriberSlice",
  initialState: initialState,
  reducers: {
    getSubscribers: (state, action) => {
      state.isLoading = true;
    },
    getSubscriber: (state, action) => {
      state.isLoading = true;
    },
    getSubscribersSuccess: (state, action) => {
      state.subscribers = action.payload;
    },
    getSubscriberSuccess: (state, action) => {
      state.subscriber = action.payload;
    },
    setSubscriber: (state, action) => {
      state.subscriber = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setStateSubscriber: (state: any, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
      state.isLoading = false;
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
      state.isLoading = false;
    },
    createSubscriber: (state, action) => {
      state.isLoading = true;
    },
    updateSubscriber: (state, action) => {
      state.isLoading = true;
    },
    deleteSubscriber: (state, action) => {
      state.isLoading = true;
    },
    setPassByAdmin: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  getSubscribers,
  getSubscriber,
  getSubscribersSuccess,
  getSubscriberSuccess,
  setSubscriber,
  setQuery,
  setStateSubscriber,
  createSubscriber,
  updateSubscriber,
  deleteSubscriber,
  setPassByAdmin,
  setFilter,
} = subscriberSlice.actions;
export default subscriberSlice.reducer;
