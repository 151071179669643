import { getHerdsSuccess, getPigByUserId } from "features/herdSlice";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getHerds(data: ActionType): any {
  const { callback, body, query, params, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.herds}/${params ?? ""}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getHerdsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* getBarnByHerd(data: ActionType): any {
  const { callback, body, query, params, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.herds}/${params ?? ""}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getHerdsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* getPigByUser(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.pigByUserId}/${params}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* createHerd(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.herdGuest}/${params}`;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* updateHerd(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.herds}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteHerd(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.herds}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* herdSaga() {
  yield takeLatest("herdSlice/getHerds", getHerds);
  yield takeEvery("herdSlice/createHerd", createHerd);
  yield takeLatest("herdSlice/updateHerd", updateHerd);
  yield takeLatest("herdSlice/deleteHerd", deleteHerd);
  yield takeLatest("herdSlice/getBarnByHerd", getBarnByHerd);
  yield takeLatest("herdSlice/getPigByUserId", getPigByUser);
}
