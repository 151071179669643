import {
  getNotificationTemplatesSuccess,
  getUserSuccess,
  getUsersSuccess,
} from "features/userSlice";
import queryString from "query-string";
import { call, put, takeLatest } from "redux-saga/effects";
import { getRoles } from "sagas/roleSaga";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
const _ = require("lodash");
export function* getUsers(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.user}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getUsersSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getNotificationTemplates(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.notificationTemplates}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getNotificationTemplatesSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getUser(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.user}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      yield put(getUserSuccess(_.get(res, "data", {})));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* createUser(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.user;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* seandNotificationUser(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.user + "/send-messages";
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* setPassByAdmin(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.setpass;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* updateUser(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.user}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      yield put(getUserSuccess(_.get(res, "data", {})));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* deleteUser(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.user}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success();
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* userSaga() {
  yield takeLatest("userSlice/getUsers", getUsers);
  yield takeLatest("userSlice/getUser", getUser);
  yield takeLatest("userSlice/updateUser", updateUser);
  yield takeLatest("userSlice/createUser", createUser);
  yield takeLatest("userSlice/deleteUser", deleteUser);
  yield takeLatest("userSlice/setPassByAdmin", setPassByAdmin);
  yield takeLatest("userSlice/seandNotification", seandNotificationUser);
  yield takeLatest(
    "userSlice/getNotificationTemplates",
    getNotificationTemplates
  );
}
