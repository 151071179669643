/*
 * Import Libraries
 */

import React, { memo } from "react";
import { Layout, Modal } from "antd";
import _ from "lodash";
import { Redirect, Route } from "react-router";
import { useSelector, useDispatch } from "react-redux";

/*
 * Import data
 */
import { setModal } from "features/modalSlice";
import SideBar from "components/Sidebar/Sidebar";
import AdminHeader from "components/Headers/AdminHeader";
import { closeModal } from "utils";
import route from "routes";

/*
 * function getRoute
 */
const getRoutes = (routes: any) => {
  return routes.map((prop: any, key: any) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === "/admin") {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};
//function check login
function CheckLogin() {
  if (!localStorage.getItem("accountInfo") || !localStorage.getItem("tokens")) {
    return <Redirect to="/auth/login" />;
  }
}

/*
 * Layout Admin Pages
 */

const Admin: React.FC = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modalReducer);
  if (!localStorage.getItem("accountInfo") || !localStorage.getItem("tokens")) {
    return <Redirect to="/auth/login" />;
  }
  CheckLogin();

  return (
    <Layout>
      <AdminHeader />
      <Layout hasSider={true}>
        <SideBar />
        <Modal
          centered
          afterClose={() => {
            closeModal(dispatch, setModal);
          }}
          key={"custom-modal"}
          open={_.get(modal, "open", false)}
          onCancel={() => {
            if (modal.onClose) {
              modal.onClose();
            }
            closeModal(dispatch, setModal);
          }}
          bodyStyle={{
            padding: 0,
          }}
          footer={false}
          title={_.get(modal, "title", "")}
          width={_.get(modal, "size", undefined)}
        >
          {_.get(modal, "content", "")}
        </Modal>
        {getRoutes(route.routesSM)}
      </Layout>
    </Layout>
  );
};

export default memo(Admin);
