/*
 * Import Libraries
 */
import _ from "lodash";
import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button, Layout, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
const { Header } = Layout;
const { Title, Text } = Typography;

/*
 * Import data
 */
import Logo from "assets/img/logo.png";
import AvatarImage from "assets/img/user-avatar-default.jpg";

/*
 * Component Header
 */
const AdminHeader: React.FC = () => {
  const history = useHistory();
  const accountInfo = JSON.parse(localStorage.getItem("accountInfo") || "{}");

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#057880",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to={"/"}>
          <img alt="logo" className="demo-logo" src={Logo} height={40} />
        </Link>
        <Title
          style={{
            color: "#FFFFFF",
            textTransform: "uppercase",
            fontFamily: "Roboto",
            fontWeight: 500,
            marginBottom: 0,
            marginLeft: 12,
          }}
          level={5}
        >
          DTA - HỆ THỐNG CHUYỂN ĐỔI SỐ TRONG CHĂN NUÔI
        </Title>
      </div>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Button
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            boxShadow: "none",
            marginTop: -10,
            paddingRight: 30,
          }}
        >
          <Avatar
            src={
              <img
                src={_.get(accountInfo, "avatar", false) || AvatarImage}
                alt="avatar"
              />
            }
          />
          <Text
            style={{
              color: "#FFFFFF",
              marginBottom: 0,
              marginLeft: 12,
            }}
          >
            {_.get(accountInfo, "name", "")}
          </Text>
        </Button>
        <Button
          style={{
            background: "no-repeat",
            border: "none",
            boxShadow: "none",
            color: "#fff",
          }}
          onClick={() => {
            localStorage.clear();
            history.push("/auth/login");
          }}
          icon={<LogoutOutlined style={{ fontSize: "22px" }} />}
        ></Button>
      </div>
    </Header>
  );
};

export default AdminHeader;
