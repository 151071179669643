/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { TypeDataShared } from "type";

/*
 * Declare type of data
 */
interface TypeDataShareState {
  typeGroups: TypeDataShared[]; //
  typeGroup: TypeDataShared | null;
  fields: TypeDataShared | null;
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: TypeDataShareState = {
  typeGroups: [],
  typeGroup: null,
  fields: null,
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager type Data Shared
 **/

export const typeDataSharedSlice: any = createSlice({
  name: "typeDataSharedSlice",
  initialState: initialState,
  reducers: {
    getTypeGroups: (state, action) => {
      state.isLoading = true;
    },

    getTypeGroup: (state, action) => {
      state.isLoading = true;
    },

    setTypeGroup: (state, action) => {
      state.typeGroup = action.payload;
      state.isLoading = false;
    },

    updateTypeGroup: (state, action) => {
      state.isLoading = true;
    },
    getTypeGroupsSuccess: (state, action) => {
      state.typeGroups = action.payload;
      state.isLoading = false;
    },
    createTypeGroup: (state, action) => {
      state.isLoading = true;
    },
    deleteTypeGroup: (state, action) => {
      state.isLoading = true;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
  },
});
export const {
  getTypeGroups,
  getTypeGroup,
  setTypeGroup,
  updateTypeGroup,
  getTypeGroupsSuccess,
  createTypeGroup,
  deleteTypeGroup,
  setQuery,
} = typeDataSharedSlice.actions;
export default typeDataSharedSlice.reducer;
