/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { ParameterType } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  parameters: ParameterType[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  parameters: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 9999,
  },
};

/**
 *  Slice manager parameters
 **/
export const parameterSlice: any = createSlice({
  name: "parameterSlice",
  initialState: initialState,
  reducers: {
    getParameters: (state, action) => {
      state.isLoading = true;
    },
    getParametersSuccess: (state, action) => {
      state.parameters = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createParameters: (state, action) => {
      state.isLoading = true;
    },
    updateParameters: (state, action) => {
      state.isLoading = true;
    },
    deleteParameters: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createParameters,
  updateParameters,
  deleteParameters,
  getParameters,
  getParametersSuccess,
  setQuery,
} = parameterSlice.actions;
export default parameterSlice.reducer;
