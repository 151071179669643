import { Button, Form, Input, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import { createSharedData, updateSharedData } from "features/sharedDataSlice";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { PayloadType, TypeDataShared } from "type";
import * as yup from "yup";

interface CUSickProp {
  getListFood?: any;
  rowUpdate?: any;
  setRowUpdate?: any;
  typeGroupId: any;
}
function CUSick({
  typeGroupId,
  getListFood,
  rowUpdate,
  setRowUpdate,
}: CUSickProp) {
  const dispatch = useDispatch();
  const [form] = useForm();

  //validate
  const FoodSchema = yup.object().shape({
    code: yup.string().required("Mã bệnh  không được để trống!"),
    name: yup.string().required("Tên bệnh  không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await FoodSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const initValues = rowUpdate
    ? {
        ...rowUpdate,
      }
    : {
        code: "",
        name: "",
        note: "",
      };

  //create handle
  function handleCreateFood(values: any) {
    const payload: PayloadType = {
      body: {
        ...values,
        typeGroupId: typeGroupId,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới danh mục tên bệnh thành công!",
          });
          form.resetFields();
          getListFood();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Tạo mới danh mục bệnh thất bại!",
              description: "Mã bệnh đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Tạo mới danh mục bệnh thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createSharedData(payload));
  }
  //update handle
  function handleUpdateFood(values: TypeDataShared) {
    const payload: PayloadType = {
      params: _.get(rowUpdate, "id", ""),
      body: {
        ...values,
        typeGroupId: typeGroupId,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật danh mục bệnh thành công!",
          });
          setRowUpdate(null);
          form.resetFields();
          getListFood();
          form.resetFields();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Cập nhật danh mục bệnh thất bại!",
              description: "Tên danh mục bệnh đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Cập nhật danh mục bệnh thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateSharedData(payload));
  }

  const resetForm = () => {
    form.resetFields();
    setRowUpdate(null);
  };

  useEffect(() => {
    if (!rowUpdate) {
      form.resetFields();
    }
    form.setFieldsValue({
      ...rowUpdate,
    });
  }, [rowUpdate]);

  return (
    <Form
      labelCol={{ span: 16 }}
      layout="vertical"
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={
        Object.keys(rowUpdate ?? {}).length === 0
          ? handleCreateFood
          : handleUpdateFood
      }
    >
      <Form.Item required label={"Mã bệnh "} name={"code"} rules={[yupSync]}>
        <Input placeholder="Nhập vào mã bệnh " disabled={!!rowUpdate} />
      </Form.Item>

      <Form.Item required label={"Tên bệnh "} name={"name"} rules={[yupSync]}>
        <Input placeholder="Nhập tên bệnh " />
      </Form.Item>

      <Form.Item label={"Ghi chú"} name={"note"}>
        <TextArea rows={3} placeholder={"Nhập ghi chú"} />
      </Form.Item>
      <div
        className="ant-modal-footer"
        style={{ padding: "10px 0px", textAlign: "center" }}
      >
        <Button onClick={resetForm} style={{ width: "48%" }}>
          Huỷ bỏ
        </Button>
        <Button style={{ width: "48%" }} type="primary" htmlType="submit">
          Lưu lại
        </Button>
      </div>
    </Form>
  );
}
export default CUSick;
