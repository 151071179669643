import { getPigsSuccess } from "features/pigTypeSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getPigs(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.pigs}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer) yield put(getPigsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* getPigByBarn(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.pigByBarn}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer) yield put(getPigsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* pigSaga() {
  yield takeLatest("pigTypeSlice/getPigs", getPigs);
  yield takeLatest("pigTypeSlice/getPigByBarn", getPigByBarn);
}
