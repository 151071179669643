import { getConfigSuccess, getConfigsSuccess } from "features/configSlice";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
const _ = require("lodash");
export function* getConfigs(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.configs}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getConfigsSuccess(_.get(res, "data", [])));
      callback.success(_.get(res, "data", []));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getConfig(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.configs}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      yield put(getConfigSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createConfig(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.configs;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* updateConfig(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.configs}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteConfig(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.configs}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export default function* configSaga() {
  yield takeLatest("configSlice/getConfigs", getConfigs);
  yield takeLatest("configSlice/getConfig", getConfig);
  yield takeLatest("configSlice/createConfig", createConfig);
  yield takeLatest("configSlice/updateConfig", updateConfig);
  yield takeLatest("configSlice/deleteConfig", deleteConfig);
}
