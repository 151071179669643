import { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Table,
  Typography,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { getUser } from "features/userSlice";
import { PayloadType, Role, SelectOptionType, UserType } from "type";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "utils";
import { useSelector } from "react-redux";
import queryString from "query-string";
import {
  getFemalePigs,
  getMalePigs,
  setQuery,
  getMeatPigs,
} from "features/pigSlice";
import _, { set } from "lodash";
import moment from "moment";
import { getProvinces } from "features/provinceSlice";
import { FULL_DATE_FORMAT } from "const";
import { getHerds } from "features/herdSlice";
interface DataType {
  phone: string;
  name: string;
  nameForPig: string;
  farmName: string;
  farmTypeId: string;
  farmScaleId: string;
  email: string;
  personInChargeId: string;
  address: string;
  provinceId: string;
  servicePackageId: string;
  serviceTimeId: string;
}
interface UserInfo {
  idUpdate: string | undefined;
  data: DataType;
}
interface ColumnType {
  day: string;
  type: string;
  point: string;
  time: string;
}
const { Title } = Typography;
function ViewHistory(data: any) {
  const dispatch = useDispatch();
  const PigActive = _.get(data, "record", []);
  const keyTab = _.get(data, "keyTab", []);
  const [dataFirstHerd, setDataFirstHerd] = useState<string[]>([]);
  const [detailHistory, setDetailHistory] = useState<string[]>([]);
  const { query } = useSelector((state: any) => state.PigReducer);

  //lấy ra thông tin trại được tạo với người dùng
  const getHerdType = (userId: string) => {
    const payload: PayloadType = {
      query: queryString.stringify({
        level: 0,
        sortBy: "createdAt:desc",
        userId: userId,
        populate: "farmScaleId, herdStatId, typeId",
      }),
      skipUpdateReducer: true,
      callback: {
        success(listHerdTypes) {
          const results = _.get(listHerdTypes, "results", []);
          if (!!results && Array.isArray(results)) {
            setDataFirstHerd(results[0]);
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerds(payload));
  };
  useEffect(() => {
    getHerdType(data?.id);
  }, []);
  const columnTabs = [
    {
      title: "Ngày phối",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",

      width: 400,
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return moment.unix(data.eventDate).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      },
    },

    {
      title: "Mã thẻ tai nái",
      dataIndex: "code",
      key: "code",
      width: 200,
    },

    {
      title: "Mã thẻ tai đực",
      dataIndex: "code",
      key: "code",
      width: 200,
    },

    {
      title: "Ngày đẻ dự kiến",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",

      width: 400,
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return moment.unix(data.eventDate).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Số con sinh ra",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return data.totalPigletsBorn;
        } else {
          return "-";
        }
      },
      width: 700,
    },

    {
      title: "Số con chết do dị tật",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return data.disabledPiglets;
        } else {
          return "-";
        }
      },
      width: 700,
    },

    {
      title: "Số con chết sau sinh",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return data.deadPiglet;
        } else {
          return "-";
        }
      },
      width: 700,
    },

    {
      title: "Số con chết khô",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return data.driedPiglet;
        } else {
          return "-";
        }
      },
      width: 700,
    },
    {
      title: "Số lợn con ",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return data.totalPigletsBorn;
        } else {
          return "-";
        }
      },
      width: 700,
    },

    {
      title: "Trọng lượng TB(kg)",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return data.averageWeight;
        } else {
          return "-";
        }
      },
      width: 700,
    },

    {
      title: "Ngày đẻ thực tế",
      dataIndex: ["pigStatId", "LON_NAI_DE"],
      key: "pigStatId.LON_NAI_DE",

      width: 400,
      render(a: any) {
        const data = _.get(a, "[0][0]", "");
        if (data.actionCode === "NDE") {
          return moment.unix(data.eventDate).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      },
    },
  ];

  const femaleColumns: any = [
    {
      key: "weekOfMating",
      dataIndex: "weekOfMating",
      title: "Tuần phối",
      width: "w-20",
      className: "text-center",
    },
    {
      key: "dateOfMating",
      dataIndex: "dateOfMating",
      title: "Ngày phối",
      width: "w-28",
    },
    {
      key: "maleCode",
      dataIndex: "maleCode",
      title: "Số tai đực",
      width: "w-28",
    },
    {
      key: "expectedDOB",
      dataIndex: "expectedDOB",
      title: "Ngày đẻ dự kiến",
      width: "w-32",
    },
    {
      key: "actualDOB",
      dataIndex: "actualDOB",
      title: "Ngày đẻ thực tế",
      width: "w-32",
    },

    {
      key: "deadPiglet",
      dataIndex: "deadPiglet",
      title: "Chết",
      className: "text-center",
    },
    {
      key: "driedPiglet",
      dataIndex: "driedPiglet",
      title: "Khô",
      className: "text-center",
    },
    {
      key: "disabledPiglets",
      dataIndex: "disabledPiglets",
      title: "Dị tật",
      className: "text-center",
    },

    {
      key: "numOfPigletsLive",
      dataIndex: "numOfPigletsLive",
      title: "Số lợn con",
      width: "w-24",
      className: "text-center",
    },
    {
      key: "averageWeight",
      dataIndex: "averageWeight",
      title: "Trọng lượng",
      width: "w-24",
      className: "text-center",
    },
    {
      key: "weaningDate",
      dataIndex: "weaningDate",
      title: "Ngày cai sữa",
      width: "w-28",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Số con cai sữa",
      width: "w-24",
      className: "text-center",
    },
    {
      key: "numOfWatingToMating",
      dataIndex: "numOfWatingToMating",
      title: "Số ngày chờ phối",
      width: "w-24",
      className: "text-center",
    },
  ];

  const maleColumns: any = [
    {
      key: "eventDate",
      dataIndex: "eventDate",
      title: "Ngày khai thác tinh",
    },
    {
      key: "volumeOfSemen",
      dataIndex: "volumeOfSemen",
      title: "Dung tích tinh (ml)",
    },
    {
      key: "numberOfSemen",
      dataIndex: "numberOfSemen",
      title: "Số lượng tinh (10^2 con)",
    },
    {
      key: "lifeRate",
      dataIndex: "lifeRate",
      title: "Tỷ lệ tinh sống %",
    },
    {
      key: "aVolumeOfSemen",
      dataIndex: "aVolumeOfSemen",
      title: "Dung tích 1 liều (ml)",
    },
    {
      key: "amountPerDose",
      dataIndex: "amountPerDose",
      title: "Số lượng tinh / liều (10^2 con)",
    },
    {
      key: "crystalMiner",
      dataIndex: "crystalMiner",
      title: "Cán bộ khai thác tinh",
    },
    {
      key: "note",
      dataIndex: "note",
      title: "Ghi chú",
    },
  ];

  const femaleTableData = _.get(PigActive, "pigStatId.LON_NAI_DE", []).map(
    (item: any) => {
      const npg = item.find((val: any) => val.actionCode === "NPG");
      const nde = item.find((val: any) => val.actionCode === "NDE");
      const ncs = item.find((val: any) => val.actionCode === "NCS");
      return {
        weekOfMating: npg?.eventDate ? moment.unix(npg.eventDate).week() : "",
        dateOfMating: npg?.eventDate
          ? moment.unix(npg.eventDate).format("DD/MM/YYYY")
          : "",
        maleCode: npg?.partnerId ?? "",
        expectedDOB: npg?.expectedDOB
          ? moment.unix(npg.expectedDOB).format("DD/MM/YYYY")
          : "",
        actualDOB: nde?.eventDate
          ? moment.unix(nde.eventDate).format("DD/MM/YYYY")
          : "",
        totalPigletsBorn: _.get(nde, "totalPigletsBorn", ""),
        deadPiglet: _.get(nde, "deadPiglet", ""),
        driedPiglet: _.get(nde, "driedPiglet", ""),
        disabledPiglets: _.get(nde, "disabledPiglets", ""),
        numOfPigletsLive: _.get(nde, "numOfPigletsLive", ""),
        averageWeight: _.get(nde, "averageWeight", ""),
        weaningDate: ncs?.eventDate
          ? moment.unix(ncs.eventDate).format("DD/MM/YYYY")
          : "",
        amount: _.get(nde, "amount", ""),
        numOfWatingToMating: _.get(ncs, "numOfWatingToMating", ""),
      };
    }
  );

  const maleTableData = _.get(
    PigActive,
    "pigStatId.LON_DUC_KHAI_THAC_TINH",
    []
  ).map((item: any) => {
    const dktt = item.find((val: any) => val.actionCode === "DKTT");
    return {
      eventDate: dktt?.eventDate ? moment.unix(dktt.eventDate).week() : "",
      volumeOfSemen: _.get(dktt, "volumeOfSemen", ""),
      numberOfSemen: _.get(dktt, "numberOfSemen", ""),
      lifeRate: _.get(dktt, "lifeRate", ""),
      aVolumeOfSemen: _.get(dktt, "aVolumeOfSemen", ""),
      amountPerDose: _.get(dktt, "amountPerDose", ""),
      crystalMiner: _.get(dktt, "crystalMiner", ""),
      note: _.get(dktt, "note", ""),
    };
  });

  return (
    <>
      <div className="ant-modal-body">
        <Row>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Mã thẻ tai "
              value={_.get(PigActive, "code", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Số lứa đẻ"
              value={_.get(PigActive, "pigStatId.numOfLitters", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Ngày sinh"
              value={moment(_.get(PigActive, "dob")).format("DD/MM/YYYY")}
            />
          </Col>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Chuồng"
              value={_.get(PigActive, "herdId.name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Dòng dõi"
              value={_.get(PigActive, "additionInfo.lineage", "")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Ngày nhập"
              value={moment(_.get(PigActive, "dateImport")).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
        </Row>

        <Table
          columns={keyTab == "PG" ? femaleColumns : maleColumns}
          rowKey={"id"}
          dataSource={keyTab == "PG" ? femaleTableData : maleColumns}
          pagination={{
            total: _.get(detailHistory, "totalResults", 0),
            current: _.get(query, "page", 1),
            pageSize: _.get(query, "limit", 3),
            defaultPageSize: 3,
            showSizeChanger: true,
            pageSizeOptions: ["3", "10", "20", "50", "100"],
            showTotal: (total, range) => {
              return `Có tất cả ${total} bản ghi`;
            },
            onChange(page, pageSize) {
              if (_.get(query, "limit", 0) !== pageSize) {
                dispatch(
                  setQuery({
                    ...query,
                    page: 1,
                    limit: pageSize,
                  })
                );
                return;
              }
              dispatchEvent(
                setQuery({
                  ...query,
                  page,
                  limit: pageSize,
                })
              );
            },
          }}
          scroll={{ x: 1300 }}
        />
      </div>

      <div className="ant-modal-footer">
        <Button
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Quay lại
        </Button>
      </div>
    </>
  );
}

export default ViewHistory;
