/**
 * Màn hình hiển thị danh sách tài khoản người dùng
 */
import { useState, useEffect } from "react";

import {
  Button,
  Popconfirm,
  Table,
  Typography,
  notification,
  Input,
  Space,
  Modal,
  Select,
  Popover,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import { ModalInfoType, PayloadType, DataTypeSubscriber } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import CUUser from "pages/User/ListUser/CUUser";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import RUser from "pages/User/ListUser/RUser";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getSubscribers,
  setQuery,
  updateSubscriber,
} from "features/subscriberSlice";
import queryString from "query-string";
import moment from "moment";
import { MONITORING } from "const";
import {
  getNotificationTemplates,
  getNotificationTemplatesSuccess,
  seandNotification,
} from "features/userSlice";
import TextArea from "antd/lib/input/TextArea";
const { Title } = Typography;

const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function ListUser() {
  const dispatch = useDispatch();
  const { query, subscribers } = useSelector(
    (state: any) => state.subscriberReducer
  );
  const { notificationTemplates } = useSelector(
    (state: any) => state.userReducer
  );
  const [searchText, setSearchText] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const [contentNotification, setContentNotification] = useState("");
  const [titleNotification, setTitleNotification] = useState("");
  const [selectNotification, setSelectNotification] = useState(undefined);
  //Lấy danh sách tài khoản người dùng
  function handleGetUsers() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate:
            "personInChargeId,dataEntryClerk,techSupporter, personalConfig. farmScaleId  farmTypeId",
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getSubscribers(payload));
  }
  // checkbox table
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // lấy mẫu thông báo
  function handleGetNotificationTemplates() {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipNull: true,
        skipEmptyString: true,
      }),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getNotificationTemplates(payload));
  }

  useEffect(() => {
    handleGetUsers();
  }, [query]);

  useEffect(() => {
    if (notificationModal) handleGetNotificationTemplates();
  }, [notificationModal]);

  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        phone: searchText,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const columns: ColumnsType<DataTypeSubscriber> = [
    {
      title: "Tên đăng nhập",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      render(value, record) {
        const notificationDate =
          _.get(record, "notificationsHistory", [])[0]?.date ?? "";
        const notificationTitle =
          _.get(record, "notificationsHistory", [])[0]?.notificationTitle ?? "";
        const notificationContent =
          _.get(record, "notificationsHistory", [])[0]?.notificationBody ?? "";
        return (
          <div>
            <div>{value ?? ""}</div>
            {notificationDate && (
              <div>
                Cập nhật thông báo{" "}
                {moment(notificationDate).format("DD/MM/YYYY")}
                <Popover
                  content={
                    <div>
                      <div>Tiêu đề :{notificationTitle}</div>
                      <div>Nội dung :{notificationContent}</div>
                    </div>
                  }
                  title=""
                  trigger="click"
                >
                  <QuestionCircleTwoTone style={{ marginLeft: "4px" }} />
                </Popover>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Họ tên chủ trang trại",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Kỹ thuật hỗ trợ",
      dataIndex: ["techSupporter", "name"],
      key: "techSupporter.name",
      width: 100,
    },
    {
      title: "Nhân viên nhập liệu",
      dataIndex: ["dataEntryClerk", "name"],
      key: "dataEntryClerk.name",
      width: 100,
    },
    {
      title: "Quy mô",
      dataIndex: "farmScaleTTNName",
      key: "farmScaleTTNName",
      width: 300,
      render: (val, record) => {
        const qm = [val ?? "", _.get(record, "farmScaleTTTName", "")].filter(
          (f) => f !== ""
        );
        return qm.map((q, idx) => (
          <p style={{ margin: 0 }} key={idx}>
            {q}
          </p>
        ));
      },
    },
    {
      title: "Tỉnh - Huyện",
      dataIndex: "district",
      key: "district",
      width: 200,
      render: (val, record) => {
        return (
          <>
            <p style={{ margin: 0 }}>{val}</p>
            <p style={{ margin: 0 }}>{_.get(record, "province", "")}</p>
          </>
        );
      },
    },

    {
      title: "Phương thức nhập",
      key: "regUserHerdBody.monitoring",
      dataIndex: ["regUserHerdBody", "monitoring"],
      width: 100,
      render: (val) => {
        return (val ?? [])
          .map((v: any) => {
            return MONITORING.find((x) => x.value === v)?.name ?? "";
          })
          .filter((v: any) => v !== "")
          .join(", ");
        // return "??";
      },
    },
    {
      title: "Số trại con",
      dataIndex: "numOfHerd",
      key: "numOfHerd",
      width: 100,
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val, record) => {
        return <>{!(val === "active") ? "Đóng" : "Hoạt động"}</>;
      },
      width: 200,
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 220,
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Xem thông tin tài khoản người dùng",
                  content: <RUser idUpdate={record?.id} record={record} />,
                  size: 1200,
                };
                dispatch(setModal(payload));
              }}
            >
              Xem
            </a>
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa tài khoản người dùng",
                  content: (
                    <CUUser idUpdate={record?.id ?? false} record={record} />
                  ),
                  size: 700,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <CustomPopconfirm
              title={`Bạn có chắc chắn muốn ${
                record?.status === "active" ? "khóa" : "mở khóa"
              } tài khoản ${record?.name ?? ""}  không ?`}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id;
                if (id) {
                  const payload: PayloadType = {
                    params: id,
                    body: {
                      email: record?.email,
                      name: record?.name,
                      address: record?.address,
                      phone: record?.phone,
                      personInChargeId: record?.personInChargeId?.id,
                      status: record?.status === "active" ? "lock" : "active",
                    },
                    callback: {
                      success: () => {
                        notification.success({
                          message:
                            record?.status === "active"
                              ? "Khóa tài khoản người dùng thành công!"
                              : "Mở khóa tài khoản người dùng thành công!",
                        });
                        handleGetUsers();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: `${
                            record?.status === "active" ? "Khóa" : "Mở khóa"
                          } tài khoản người dùng thành công!`,
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(updateSubscriber(payload));
                }
              }}
            >
              <a>{record?.status === "active" ? "Đóng" : "Mở"}</a>
            </CustomPopconfirm>
          </Space>
        );
      },
    },
  ];
  const showModal = () => {
    setNotificationModal(true);
  };
  const [loadding, setLoadding] = useState(false);
  const handleOk = async () => {
    setLoadding(true);

    const payload: PayloadType = {
      body: {
        users: selectedRowKeys.map((item: any) => {
          return {
            userId: item,
            content: contentNotification,
            title: titleNotification,
          };
        }),
      },

      callback: {
        success(resultHerdCreate) {
          notification.success({
            message: "Gửi thông báo thành công!",
          });
          setContentNotification("");
          setTitleNotification("");
          setSelectNotification(undefined);
          setSelectedRowKeys([]);
          setNotificationModal(false);
          setLoadding(false);
          handleGetUsers();
        },
        failed(errorMessage) {
          notification.error({
            message: "Gửi thông báo thất bại!",
            description: errorMessage,
          });
          setLoadding(false);
        },
      },
    };
    await dispatch(seandNotification(payload));
  };

  const handleCancel = () => {
    setContentNotification("");
    setTitleNotification("");
    setNotificationModal(false);
    setSelectNotification(undefined);
  };
  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Search
            placeholder="Tìm kiếm theo tên đăng nhập "
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 242 }}
          />
          {selectedRowKeys.length ? (
            <Button
              type="primary"
              onClick={() => {
                setNotificationModal(true);
              }}
            >
              Gửi thông báo
            </Button>
          ) : (
            ""
          )}
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới tài khoản người dùng",
                content: <CUUser idUpdate={undefined} />,
                size: 1031,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(subscribers, "results", [])}
        rowClassName={(record: any) =>
          record?.status === "active" ? "table-row-open" : "table-row-close"
        }
        rowSelection={rowSelection}
        pagination={{
          total: _.get(subscribers, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
      <Modal
        title="Gửi thông báo"
        open={notificationModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={680}
        okText="Lưu"
        confirmLoading={loadding}
        cancelText="Hủy"
      >
        <Title level={3}>Thông báo mẫu</Title>
        <Select
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder="Chọn mẫu thông báo"
          optionFilterProp="children"
          value={selectNotification}
          options={_.get(notificationTemplates, "results", []).map(
            (item: any) => ({
              label: _.get(item, "title", ""),
              value: item.id,
            })
          )}
          onChange={(value) => {
            // Tìm phần tử được chọn
            const selectedItem = _.find(
              _.get(notificationTemplates, "results", []),
              { id: value }
            );

            // Cập nhật nội dung vào TextArea
            setContentNotification(_.get(selectedItem, "content", ""));
            setTitleNotification(_.get(selectedItem, "title", ""));
            setSelectNotification(value);
          }}
        />
        <Title level={3} style={{ marginTop: "12px" }}>
          Tiêu đề
        </Title>
        <TextArea
          rows={4}
          value={titleNotification}
          onChange={(e) => setTitleNotification(e.target.value)}
        />
        <Title level={3} style={{ marginTop: "12px" }}>
          Nội dung
        </Title>
        <TextArea
          rows={4}
          value={contentNotification}
          onChange={(e) => setContentNotification(e.target.value)}
        />
      </Modal>
    </>
  );
}

export default ListUser;
