import { Button, Form, InputNumber, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { MaleGrowthStandardTemp, PayloadType, UserType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect } from "react";
import * as yup from "yup";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  createGrowthStandardTemp,
  updateGrowthStandardTemp,
} from "features/growthStandardTempSlice";

function CUGrowthStandard({
  idUpdate = undefined,
  record = undefined,
  handleAfterScuccess,
}: {
  idUpdate?: any;
  record?: MaleGrowthStandardTemp;
  handleAfterScuccess?: () => void;
}) {
  const [form] = useForm();
  const dispatch = useDispatch();

  const { open } = useSelector((state: any) => state.modalReducer);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else {
      form.setFieldValue("weekOlds", _.get(record, "weekOlds", ""));
      form.setFieldValue("weightAvg", _.get(record, "weightAvg", ""));
    }
  }, [record, open]);

  yup.addMethod(yup.number, "nullableNumber", function (message) {
    return this.transform((value, originalValue) =>
      originalValue === null ? NaN : value
    ).typeError(message);
  });

  const formSchema = yup.object().shape({
    weekOlds: (yup.number() as any)
      .nullableNumber("Vui lòng nhập tuần tuổi!")
      .required("Vui lòng nhập tuần tuổi!"),
    weightAvg: (yup.number() as any)
      .nullableNumber("Vui lòng nhập trọng lượng trung bình!")
      .required("Vui lòng nhập trọng lượng trung bình!"),
  });

  const yupSync = [getYupSync(formSchema)];
  function handleSubmit() {
    const values = form.getFieldsValue(["weekOlds", "weightAvg"]);
    const body = _.cloneDeep(values);
    console.log(body);
    if (!idUpdate) {
      handleCreateGrowthStandardTemp(body);
      return;
    }
    handleUpdateGrowthStandardTemp(body);
  }
  function handleCreateGrowthStandardTemp(values: MaleGrowthStandardTemp) {
    const payload: PayloadType = {
      body: values,
      callback: {
        success() {
          notification.success({
            message:
              "Tạo mới thông số tiêu chuẩn trọng lượng trung bình thành công!",
          });
          !!handleAfterScuccess && handleAfterScuccess();
          closeModal(dispatch, setModal);
        },
        failed(errorMessage) {
          notification.error({
            message:
              "Không thể tạo mới thông số tiêu chuẩn trọng lượng trung bình!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createGrowthStandardTemp(payload));
  }
  function handleUpdateGrowthStandardTemp(values: UserType) {
    const payload: PayloadType = {
      params: idUpdate,
      body: values,
      callback: {
        success() {
          notification.success({
            message:
              "Cập nhật thông số tiêu chuẩn trọng lượng trung bình thành công!",
          });
          !!handleAfterScuccess && handleAfterScuccess();
          closeModal(dispatch, setModal);
        },
        failed(errorMessage) {
          notification.error({
            message:
              "Không thể cập nhật thông số tiêu chuẩn trọng lượng trung bình!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateGrowthStandardTemp(payload));
  }

  const initValues = {
    weekOlds: null,
    weightAvg: null,
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={"Số tuần tuổi"}
          name={"weekOlds"}
          rules={yupSync}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Nhập vào số tuần tuổi"
          />
        </Form.Item>
        <Form.Item
          required
          label={"Trọng lượng TB"}
          name={"weightAvg"}
          rules={yupSync}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Nhập vào trọng lượng trung bình"
          />
        </Form.Item>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
        >
          {idUpdate ? "Lưu lại" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUGrowthStandard;
