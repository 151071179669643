/**
 * Tab Nhóm khác
 */
import { useState, useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  Select,
  Table,
  Layout,
  Typography,
  notification,
  Switch,
  Input,
  Space,
} from "antd";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
const { Content } = Layout;
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import moment from "moment";
import { useHistory } from "react-router";
import {} from "antd";
import { ModalInfoType, PayloadType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import queryString from "query-string";
import {
  getPromotionDetails,
  setQuery,
  updatePromotionDetails,
} from "features/promotionDetailSlice";
import CUPromotion from "./CUPromotion";
const { Title } = Typography;
interface DataType {
  phone: string;
  name: string;
  nameForPig: string;
  farmName: string;
  farmTypeId: string;
  farmScaleId: string;
  email: string;
  personInChargeId: string;
  address: string;
  provinceId: string;
  servicePackageId: string;
  serviceTimeId: string;
}
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function RPromotion(dataView: any) {
  const record = dataView?.history?.location.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useSelector((state: any) => state.promotionDetailReducer);
  const [listPromotionDetail, setListPromotionDetail] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");

  //Lấy danh sách chi tiết khuyến mãi theo id khuyến mãi
  function getDetailPromotionByID() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          promotionId: record?.id,
          populate: "promotionId",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {
          setListPromotionDetail(values);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotionDetails(payload));
  }
  useEffect(() => {
    getDetailPromotionByID();
  }, [query]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Mã KM",
      dataIndex: "code",
      key: "code",
      width: 200,
    },
    {
      title: "Chương trình khuyến mãi",
      dataIndex: ["promotionId", "name"],
      key: "promotionId.name",
      width: 400,
    },
    {
      title: "DV áp dụng",
      dataIndex: ["promotionId", "value"],
      key: "promotionId.value",
      width: 200,
      render: (value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
    },
    {
      title: "Tổng SL",
      dataIndex: "amount",
      key: "amount",
      width: 100,
    },

    {
      title: "SL đã dùng",
      dataIndex: "usedAmount",
      key: "usedAmount",
      width: 200,
    },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      width: 300,
      render: (val, record) => {
        return <>{moment(val).utc().format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Thời gian kết thúc",
      dataIndex: "endDate",
      key: "endDate",
      width: 300,
      render: (val, record) => {
        return <>{moment(val).utc().format("DD/MM/YYYY")}</>;
      },
    },

    {
      title: "Trạng thái KH",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val, record: any) => {
        return (
          <Space direction="vertical">
            {record.status === "active" ? (
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                onChange={() => {
                  const idUpdate = record?.id ?? "";
                  if (idUpdate) {
                    const payload: PayloadType = {
                      params: idUpdate,
                      body: {
                        code: record?.code,
                        servicePackId: record?.servicePackId,
                        amount: record?.amount,
                        promotionId: record?.promotionId.id,
                        startDate: record?.startDate,
                        endDate: record?.endDate,
                        status: record?.status === "active" ? "lock" : "active",
                      },
                      callback: {
                        success: (values) => {
                          notification.success({
                            message:
                              "Thay đổi trạng thái kích hoạt thành công!",
                          });
                          getDetailPromotionByID();
                        },
                        failed(errorMessage) {
                          notification.error({
                            message: `Thay đối trạng thái kích hoạt không thành công!`,
                            description: errorMessage,
                          });
                        },
                      },
                    };
                    dispatch(updatePromotionDetails(payload));
                  }
                }}
                style={{
                  width: 65,
                }}
                defaultChecked={true}
              />
            ) : (
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                onChange={() => {
                  const idUpdate = record?.id ?? "";
                  if (idUpdate) {
                    const payload: PayloadType = {
                      params: idUpdate,
                      body: {
                        code: record?.code,
                        servicePackId: record?.servicePackId,
                        amount: record?.amount,
                        promotionId: record?.promotionId.id,
                        startDate: record?.startDate,
                        endDate: record?.endDate,
                        status: record?.status === "active" ? "lock" : "active",
                      },
                      callback: {
                        success: (values) => {
                          notification.success({
                            message:
                              "Thay đổi trạng thái kích hoạt thành công!",
                          });
                          getDetailPromotionByID();
                        },
                        failed(errorMessage) {
                          notification.error({
                            message: `Thay đối trạng thái kích hoạt không thành công!`,
                            description: errorMessage,
                          });
                        },
                      },
                    };
                    dispatch(updatePromotionDetails(payload));
                  }
                }}
                style={{
                  width: 65,
                }}
                defaultChecked={false}
              />
            )}
          </Space>
        );
      },
    },
  ];
  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        code: searchText, //Trường lọc data là name và
      })
    );
  };

  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 20,
        marginTop: 16,
        marginRight: 20,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <div
          className="space-between"
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <a
            style={{ color: "#000" }}
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowLeftOutlined />
          </a>
          <Title
            style={{
              marginBottom: 0,
              paddingLeft: 30,
              paddingTop: 16,
              paddingBottom: 16,
            }}
            level={4}
          >
            Chi tiết chương trình khuyến mãi
          </Title>
        </div>

        <Row
          style={{
            paddingTop: 16,
            paddingLeft: 20,
            paddingRight: 20,
            background: "rgba(242, 242, 242, 0.50)",
          }}
        >
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tên chương trình"
              value={_.get(record, "name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Hình thức: "
              value={
                _.get(record, "form", "") === "money"
                  ? "Giảm trực tiếp"
                  : "Theo phần trăm"
              }
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Giá trị: "
              value={_.get(record, "value", "")}
            />
          </Col>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Thời gian bắt đầu:  "
              value={moment(_.get(record, "startDate", ""))
                .utc()
                .format("DD/MM/YYYY")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Thời gian kết thúc: "
              value={moment(_.get(record, "endDate", ""))
                .utc()
                .format("DD/MM/YYYY")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Trạng thái: "
              value={
                _.get(record, "status", "") === "active" ? "Hoạt động" : "Đóng"
              }
            />
          </Col>
        </Row>
        <div
          className="space-between"
          style={{
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Title level={5}>Danh sách chi tiết khuyến mãi</Title>
          <Space className="custom-placeholder-select">
            <Search
              placeholder="Tìm kiếm theo mã khuyến mãi"
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={handleSearch}
              style={{ width: 242 }}
            />
          </Space>
        </div>
        <Table
          style={{ paddingLeft: 20, paddingRight: 20 }}
          columns={columns}
          rowKey={"id"}
          dataSource={_.get(listPromotionDetail, "results", [])}
          pagination={{
            total: _.get(listPromotionDetail, "totalResults", []),
            current: _.get(query, "page", 1),
            pageSize: _.get(query, "limit", 10),
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
            showTotal: (total, range) => {
              return `Có tất cả ${total} bản ghi`;
            },
            onChange(page, pageSize) {
              if (_.get(query, "limit", 0) !== pageSize) {
                dispatch(
                  setQuery({
                    ...query,
                    page: 1,
                    limit: pageSize,
                  })
                );
                return;
              }
              dispatch(
                setQuery({
                  ...query,
                  page,
                  limit: pageSize,
                })
              );
            },
          }}
          scroll={{ x: 1300 }}
        />
      </Space>
    </Content>
  );
}

export default RPromotion;
