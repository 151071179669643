/**
 * Tab Nhóm khác
 */
import { useEffect } from "react";

import { Button, Popconfirm, Table, Typography, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { Space } from "antd";
import { MaleGrowthStandardTemp, ModalInfoType, PayloadType } from "type";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import queryString from "query-string";
import CUGrowthStandardTemp from "./CUGrowthStandardTemp";
import {
  deleteGrowthStandardTemp,
  getGrowthStandardTemps,
  setQuery,
} from "features/growthStandardTempSlice";
const { Title } = Typography;

function ListGrowthStandard() {
  const dispatch = useDispatch();
  const { query, growthStandardTemps } = useSelector(
    (state: any) => state.growthStandardTempReducer
  );

  //Lấy danh sách gói dịch vụ
  function handleGetGrowthStandardTemps() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          sortBy: "_id:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };

    dispatch(getGrowthStandardTemps(payload));
  }
  useEffect(() => {
    handleGetGrowthStandardTemps();
  }, [query]);

  const columns: ColumnsType<MaleGrowthStandardTemp> = [
    {
      title: "Số tuần tuôi",
      dataIndex: "weekOlds",
      key: "weekOlds",
    },

    {
      title: "Trọng lượng trung bình (kg)",
      dataIndex: "weightAvg",
      key: "weightAvg",
      align: "center",
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa thông số tiêu chuẩn trọng lượng trung bình",
                  content: (
                    <CUGrowthStandardTemp
                      idUpdate={record?.id ?? false}
                      record={record}
                      handleAfterScuccess={handleGetGrowthStandardTemps}
                    />
                  ),
                  size: 600,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id;
                const payload: PayloadType = {
                  params: id,
                  callback: {
                    success(values) {
                      notification.success({
                        message:
                          "Xoá thông số tiêu chuẩn trọng lượng trung bình thành công!",
                      });
                      handleGetGrowthStandardTemps();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message:
                          "Xoá thông số tiêu chuẩn trọng lượng trung bình thất bại!",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(deleteGrowthStandardTemp(payload));
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới thông số tiêu chuẩn trọng lượng trung bình",
                content: (
                  <CUGrowthStandardTemp
                    handleAfterScuccess={handleGetGrowthStandardTemps}
                  />
                ),
                size: 600,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(growthStandardTemps, "results", [])}
        pagination={{
          total: _.get(growthStandardTemps, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListGrowthStandard;
