/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

/*
 * Declare type of data
 */
interface AdminDataState {
  dataImports: any[]; //
  dataImportHistorys: any[]; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  isLoading: false,
  dataImports: [],
  dataImportHistorys: [],
};

/**
 *  Slice manager herd type
 **/

export const importSlice: any = createSlice({
  name: "importSlice",
  initialState: initialState,
  reducers: {
    importPig: (state, action) => {
      state.isLoading = true;
    },
    importHistoryPig: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const { importPig, importHistoryPig } = importSlice.actions;
export default importSlice.reducer;
