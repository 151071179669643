/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Config } from "type";

/*
 * Declare type of data
 */
interface PromotionDetailState {
  promotionDetails: Config[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: PromotionDetailState = {
  promotionDetails: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager promotion details
 **/
export const promotionDetailSlice: any = createSlice({
  name: "promotionDetailSlice",
  initialState: initialState,
  reducers: {
    getPromotionDetails: (state, action) => {
      state.isLoading = true;
    },
    getPromotionDetailsSuccess: (state, action) => {
      state.promotionDetails = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createPromotionDetails: (state, action) => {
      state.isLoading = true;
    },
    updatePromotionDetails: (state, action) => {
      state.isLoading = true;
    },
    deletePromotionDetails: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createPromotionDetails,
  updatePromotionDetails,
  deletePromotionDetails,
  getPromotionDetails,
  getPromotionDetailsSuccess,
  setQuery,
} = promotionDetailSlice.actions;
export default promotionDetailSlice.reducer;
