/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { FeedbackDataType } from "type";

/*
 * Declare type of data
 */
interface FeedBackDataState {
  feedbacks: FeedbackDataType[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: FeedBackDataState = {
  feedbacks: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
    populate: "userId",
    sortBy: "createdAt:des",
  },
};

/**
 *  Slice manager FeedBack
 **/
export const feedBackSlice: any = createSlice({
  name: "feedBackSlice",
  initialState: initialState,
  reducers: {
    getFeedbacks: (state, action) => {
      state.isLoading = true;
    },
    getFeedbacksSuccess: (state, action) => {
      state.feedbacks = action.payload;
      state.isLoading = false;
    },
    getFeedbackSuccess: (state, action) => {
      state.feedbacks = action.payload;
      state.isLoading = false;
    },

    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    updateFeedback: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  getFeedbacks,
  updateFeedback,
  getFeedbacksSuccess,
  getFeedbackSuccess,
  setStateFeedback,
  setQuery,
} = feedBackSlice.actions;
export default feedBackSlice.reducer;
