/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { RoleType } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  roles: RoleType[]; //
  role: RoleType | null;
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  roles: [],
  role: null,
  isLoading: false,
  query: {},
};

/**
 *  Slice manager role
 **/
export const roleSlice: any = createSlice({
  name: "roleSlice",
  initialState: initialState,
  reducers: {
    getRoles: (state, action) => {
      state.isLoading = true;
    },
    getRoleByName: (state, action) => {
      state.isLoading = true;
    },
    getRolesSuccess: (state, action) => {
      state.roles = action.payload;
      state.isLoading = false;
    },
    getRoleSuccess: (state, action) => {
      state.role = action.payload;
      state.isLoading = false;
    },
    setRoleQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createRole: (state, action) => {
      state.isLoading = true;
    },
    setRole: (state, action) => {
      state.role = action.payload;
      state.isLoading = false;
    },
  },
});
export const {
  getRoles,
  setRole,
  getRolesSuccess,
  getRoleSuccess,
  setRoleQuery,
  createRole,
  getRoleByName,
} = roleSlice.actions;
export default roleSlice.reducer;
