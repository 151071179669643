/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Product } from "type";

/*
 * Declare type of data
 */
interface ProductState {
  products: Product[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: ProductState = {
  products: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager config values
 **/
export const productSlice: any = createSlice({
  name: "productSlice",
  initialState: initialState,
  reducers: {
    getProducts: (state, action) => {
      state.isLoading = true;
    },
    getProductsSuccess: (state, action) => {
      state.products = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createProducts: (state, action) => {
      state.isLoading = true;
    },
    updateProducts: (state, action) => {
      state.isLoading = true;
    },
    deleteProducts: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createProducts,
  updateProducts,
  deleteProducts,
  getProducts,
  getProductsSuccess,
  setQuery,
} = productSlice.actions;
export default productSlice.reducer;
