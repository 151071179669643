/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Procedure } from "type";

/*
 * Declare type of data
 */
interface ProcedureState {
  procedures: Procedure[]; //
  procedureDetailCreate: Procedure | null;
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: ProcedureState = {
  procedures: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
  procedureDetailCreate: null,
};

/**
 *  Slice manager procedure
 **/
export const procedureSlice: any = createSlice({
  name: "procedureSlice",
  initialState: initialState,
  reducers: {
    getProcedures: (state, action) => {
      state.isLoading = true;
    },

    getProceduresSuccess: (state, action) => {
      state.procedures = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    setProcedureDetailCreate: (state, action) => {
      state.procedureDetailCreate = action.payload;
      state.isLoading = false;
    },
    createProcedures: (state, action) => {
      state.isLoading = true;
    },
    updateProcedures: (state, action) => {
      state.isLoading = true;
    },
    deleteProcedures: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  setProcedureDetailCreate,
  createProcedures,
  updateProcedures,
  deleteProcedures,
  getProcedures,
  getProceduresSuccess,
  setQuery,
} = procedureSlice.actions;
export default procedureSlice.reducer;
