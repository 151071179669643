/**
 * Tab Nhóm khác
 */
import { Typography, Tabs, notification, Table, Space } from "antd";
const { Title } = Typography;
import { PayloadType } from "type";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import type { ColumnsType } from "antd/es/table";
import { setModal } from "features/modalSlice";
import { closeModal } from "utils";
import route from "routes";

import {
  TAB_MANAGER_PIG_WHEN_TYPE_IS_SOW,
  TAB_MANAGER_PIG_WHEN_TYPE_IS_MIXTURE,
  TAB_MANAGER_PIG_WHEN_TYPE_IS_MEAT,
  TAB_MANAGER_HISTORY,
} from "const";
import { getFemalePigs } from "features/pigSlice";
import { memo, useEffect, useState } from "react";
import queryString from "query-string";
import moment from "moment";
import { getStatisticalByHerd, setQuery } from "features/statisticalSlice";
import { getSharedDatas } from "features/sharedDataSlice";
import { getTypeGroups } from "features/typeDataSharedSlice";
import TableHistory from "./TableHistory";
import ViewHistory from "./ViewHistory";

const TabPig = (data: any) => {
  const modal = useSelector((state: any) => state.modalReducer);
  const [herdIdActive, setHerdIdActive] = useState<any>(
    _.get(data, "data.record.id", "")
  );
  const tabListActive = _.get(data, "keyTabList", "");

  useEffect(() => {
    setHerdIdActive(_.get(data, "data.record.id", ""));
  }, [herdIdActive]);
  const { query } = useSelector((state: any) => state.StatisticalReducer);
  const [keyTab, setKeyTab] = useState<any>("");
  const [dataPigByTabStatus, setDataPigByTabStatus] = useState<any>([]);
  const [pigState, setPigState] = useState<any>([]);
  const dispatch = useDispatch();

  // call api lấy thông tin của lợn theo tình trạng hiện tại của lợn
  function getFemalePigByStatusCode(
    herdId: string,
    typeCode: string,
    statusCode?: string
  ) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          herdId: herdId,
          typeCode: typeCode,
          populate: "pigStatId, herdId",
          statusCode: statusCode,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(herds) {
          setDataPigByTabStatus(herds);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getFemalePigs(payload));
  }

  // call api lấy thông tin của lợn thịt
  function getMeatPigByStatusCode(
    herdId: string,
    stateCode?: string,
    pigTypeCode?: string
  ) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          herdId: herdId,
          stateCode: stateCode,
          pigTypeCode: pigTypeCode,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(herds) {
          setDataPigByTabStatus(herds);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getStatisticalByHerd(payload));
  }

  //  get history pig

  function getHistoryPig(herdId: string, stateCode?: string) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          herdId: herdId,
          typeCode: stateCode,
          populate: "pigStatId, herdId",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(herds) {
          setDataPigByTabStatus(herds);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getFemalePigs(payload));
  }

  // get list statId from share data

  const getStatusFromSharedData = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        code: `PIG_STATE`,
      }),
      skipUpdateReducer: true,
      callback: {
        success(roleId) {
          const results = _.get(roleId, "results", []);
          const typeGroupId = _.get(results, "[0].id", null);
          const payload1: PayloadType = {
            query: queryString.stringify({
              page: 1,
              limit: 999,
              typeGroupId: typeGroupId,
            }),
            callback: {
              success(results) {
                const listDV = results.results;
                if (!!listDV && Array.isArray(listDV)) {
                  setPigState(
                    listDV.map((item: any) => {
                      return {
                        label: item?.name,
                        value: item?.id,
                      };
                    })
                  );
                }
              },
              failed() {},
            },
          };
          dispatch(getSharedDatas(payload1));
        },
        failed() {},
      },
    };
    dispatch(getTypeGroups(payload));
  };
  useEffect(() => {
    getStatusFromSharedData();
  }, [data]);

  let dataTabs: any = [];

  const handleChangeTabs = (key: string) => {
    setKeyTab(key);
    if (tabListActive === "LIST") {
      if (key === "LD") {
        getFemalePigByStatusCode(herdIdActive, key, undefined);
      } else if (key === "LT") {
        setDataPigByTabStatus(data.data.record?.childrent);
      } else {
        getFemalePigByStatusCode(herdIdActive, "LN", key);
      }
    } else {
      if (key === "PG") {
        getHistoryPig(herdIdActive, "LN");
      } else {
        getHistoryPig(herdIdActive, "LD");
      }
    }
  };

  useEffect(() => {
    if (tabListActive === "LIST") {
      if (typeOfHerd === "TTT") {
        handleChangeTabs("LT");
      } else {
        handleChangeTabs("HB");
      }
    } else {
      handleChangeTabs("PG");
    }
  }, [data]);

  let columnTabs: any;
  const typeOfHerd: string = data.typeOfHerd;
  if (tabListActive === "LIST") {
    if (typeOfHerd === "TTN") {
      dataTabs = TAB_MANAGER_PIG_WHEN_TYPE_IS_SOW;
      switch (keyTab) {
        case "HB":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày sinh",
              dataIndex: "dob",
              key: "dob",
              width: 400,
              render(a: any) {
                return moment(a).format("DD/MM/YYYY");
              },
            },
            {
              title: "Giống",
              dataIndex: ["additionInfo", "species"],
              key: "additionInfo.species",
              width: 250,
            },

            {
              title: "Trại giống",
              dataIndex: ["additionInfo", "lineage"],
              key: "additionInfo.lineage",
              width: 250,
            },

            {
              title: "Dòng dõi",
              dataIndex: ["additionInfo", "hatchery"],
              key: "additionInfo.hatchery",
              width: 700,
            },
            {
              title: "Nguồn gốc",
              dataIndex: ["specialInfo", "origin"],
              key: "specialInfo.origin",
              width: 700,
            },
            {
              title: "Số lứa đẻ",
              dataIndex: ["specialInfo", "numberOfBirths"],
              key: "specialInfo.numberOfBirths",
              width: 700,
            },
            {
              title: "Ngày nhập",
              dataIndex: "dateImport",
              key: "dateImport",
              width: 700,
              render(a: any) {
                return moment(a).format("DD/MM/YYYY");
              },
            },
            {
              title: "Tình trạng",
              dataIndex: ["pigStatId", "stateId"],
              key: "pigStatId.stateId",
              width: 700,
              render(a: any) {
                return pigState.find((item: any) => item.value === a)?.label;
              },
            },
            {
              title: "Trạng thái",
              dataIndex: "address",
              key: "address",
              width: 700,
            },
            {
              title: "Ghi chú",
              dataIndex: "note",
              key: "note",
              width: 700,
            },
          ];
          break;
        case "CP":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày phối(dự kiến)",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDD") {
                  return moment.unix(data.eventDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Lần phối",
              dataIndex: ["pigStatId", "numOfLitters"],
              key: "numOfLitters.numOfLitters",
              width: 250,
            },

            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 250,
            },

            {
              title: "Ngày cai sữa ",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NCS") {
                  return moment.unix(data.eventDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
              width: 700,
            },
            {
              title: "Số ngày chờ phối",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");

                const dataWaiting = moment().diff(
                  moment.unix(data.eventDate),
                  "days"
                );

                return dataWaiting;
              },
              width: 700,
            },
            {
              title: "Tình trạng",
              dataIndex: ["pigStatId", "stateId"],
              key: "pigStatId.stateId",
              width: 700,
              render(a: any) {
                return pigState.find((item: any) => item.value === a)?.label;
              },
            },
          ];

          //
          break;
        case "MT":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày phối(dự kiến)",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NPG") {
                  return moment.unix(data.eventDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Ngày đẻ dự kiến",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NPG") {
                  return moment.unix(data.expectedDOB).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
            },

            {
              title: "Số ngày mang thai",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");

                const dataWaiting = moment().diff(
                  moment.unix(data.eventDate),
                  "days"
                );

                return dataWaiting;
              },
            },

            {
              title: "Mã thẻ tai đực",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NPG") {
                  return data.partnerId;
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Số lứa đẻ",
              dataIndex: ["specialInfo", "numberOfBirths"],
              key: "specialInfo.numberOfBirths",
              width: 700,
            },
          ];
          break;
        case "ND":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày đẻ",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",

              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return moment.unix(data.eventDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 250,
            },

            {
              title: "Lứa đẻ",
              dataIndex: ["specialInfo", "numberOfBirths"],
              key: "specialInfo.numberOfBirths",
              width: 250,
              render(_: any) {},
            },

            {
              title: "S.N nuôi con",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");

                const dataWaiting = moment().diff(
                  moment.unix(data.eventDate),
                  "days"
                );

                return dataWaiting;
              },
              width: 700,
            },
            {
              title: "Ngày cai sữa dự kiến",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return moment.unix(data.weaningDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
              width: 700,
            },
            {
              title: "Tổng số con sơ sinh",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.totalPigletsBorn;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Số con dị tật",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.disabledPiglets;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Số con chết",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.deadPiglet;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Số con khô",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.driedPiglet;
                } else {
                  return "-";
                }
              },
              width: 700,
            },
            {
              title: "Số lợn con ",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.totalPigletsBorn;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Trọng lượng TB(kg)",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.averageWeight;
                } else {
                  return "-";
                }
              },
              width: 700,
            },
          ];
          break;
        case "CVD":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 400,
            },
            {
              title: "Nguyên nhân ",
              dataIndex: ["pigStatId", "LON_NAI_CHET"],
              key: "pigStatId.LON_NAI_CHET",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NC") {
                  return data.reason;
                } else {
                  return "-";
                }
              },
              width: 250,
            },

            {
              title: "Ghi chú",
              dataIndex: ["pigStatId", "LON_NAI_CHET"],
              key: "pigStatId.LON_NAI_CHET",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NC") {
                  return data.note;
                } else {
                  return "-";
                }
              },
              width: 250,
            },
          ];
          break;
        case "LD":
          columnTabs = [
            {
              title: "Mã Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày sinh",
              dataIndex: "dob",
              key: "dob",
              width: 400,
              render(a: string) {
                return moment(a).format("DD/MM/YYYY");
              },
            },
            {
              title: "Ngày nhập",
              dataIndex: "dateImport",
              key: "dateImport",
              width: 250,
              render(a: string) {
                return moment(a).format("DD/MM/YYYY");
              },
            },

            {
              title: "Giống",
              dataIndex: ["additionInfo", "species"],
              key: "additionInfo.species",
              width: 250,
            },

            {
              title: "Trọng lượng(kg)",
              dataIndex: ["pigStatId", "weight"],
              key: "pigStatId.weight",
              width: 700,
            },
            {
              title: "Tình trạng",
              dataIndex: ["pigStatId", "stateId"],
              key: "pigStatId.stateId",
              width: 700,
              render(a: any) {
                return pigState.find((item: any) => item.value === a)?.label;
              },
            },
          ];
          break;
      }
    } else if (typeOfHerd === "TTTH") {
      dataTabs = TAB_MANAGER_PIG_WHEN_TYPE_IS_MIXTURE;
      switch (keyTab) {
        case "HB":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày sinh",
              dataIndex: "dob",
              key: "dob",
              width: 400,
              render(a: any) {
                return moment(a).format("DD/MM/YYYY");
              },
            },
            {
              title: "Giống",
              dataIndex: ["additionInfo", "species"],
              key: "additionInfo.species",
              width: 250,
            },

            {
              title: "Trại giống",
              dataIndex: ["additionInfo", "lineage"],
              key: "additionInfo.lineage",
              width: 250,
            },

            {
              title: "Dòng dõi",
              dataIndex: ["additionInfo", "hatchery"],
              key: "additionInfo.hatchery",
              width: 700,
            },
            {
              title: "Nguồn gốc",
              dataIndex: ["specialInfo", "origin"],
              key: "specialInfo.origin",
              width: 700,
            },
            {
              title: "Số lứa đẻ",
              dataIndex: ["specialInfo", "numberOfBirths"],
              key: "specialInfo.numberOfBirths",
              width: 700,
            },
            {
              title: "Ngày nhập",
              dataIndex: "dateImport",
              key: "dateImport",
              width: 700,
              render(a: any) {
                return moment(a).format("DD/MM/YYYY");
              },
            },
            {
              title: "Tình trạng",
              dataIndex: ["pigStatId", "stateId"],
              key: "pigStatId.stateId",
              width: 700,
              render(a: any) {
                return pigState.find((item: any) => item.value === a)?.label;
              },
            },
            {
              title: "Trạng thái",
              dataIndex: "address",
              key: "address",
              width: 700,
            },
            {
              title: "Ghi chú",
              dataIndex: "note",
              key: "note",
              width: 700,
            },
          ];
          break;
        case "CP":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },

            {
              title: "Lần phối",
              dataIndex: ["pigStatId", "numOfLitters"],
              key: "numOfLitters.numOfLitters",
              width: 250,
            },

            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 250,
            },

            {
              title: "Ngày cai sữa ",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NCS") {
                  return moment.unix(data.eventDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
              width: 700,
            },
            {
              title: "Số ngày chờ phối",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                const dataWaiting = moment().diff(
                  moment.unix(data?.eventDate),
                  "days"
                );

                return _.isNaN(dataWaiting) ? "-" : dataWaiting;
              },
              width: 700,
            },
            {
              title: "Tình trạng",
              dataIndex: ["pigStatId", "stateId"],
              key: "pigStatId.stateId",
              width: 700,
              render(a: any) {
                return pigState.find((item: any) => item.value === a)?.label;
              },
            },
          ];

          //
          break;
        case "MT":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày phối",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NPG") {
                  return moment.unix(data.eventDate).format("DD/MM/YYYY");
                }
              },
            },
            {
              title: "Ngày đẻ dự kiến",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NPG") {
                  return moment.unix(data.expectedDOB).format("DD/MM/YYYY");
                }
              },
            },
            {
              title: "Số ngày mang thai",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");

                const dataWaiting = moment().diff(
                  moment.unix(data.eventDate),
                  "days"
                );

                return dataWaiting;
              },
            },

            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 700,
            },
            {
              title: "Mã thẻ tai đực",
              dataIndex: "address",
              key: "address",
              width: 700,
            },
            {
              title: "Số lứa đẻ",
              dataIndex: ["specialInfo", "numberOfBirths"],
              key: "specialInfo.numberOfBirths",
              width: 700,
            },
          ];
          break;
        case "ND":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày đẻ",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",

              width: 400,
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return moment.unix(data.eventDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 250,
            },

            {
              title: "Lứa đẻ",
              dataIndex: ["specialInfo", "numberOfBirths"],
              key: "specialInfo.numberOfBirths",
              width: 250,
            },

            {
              title: "S.N nuôi con",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");

                const dataWaiting = moment().diff(
                  moment.unix(data.eventDate),
                  "days"
                );

                return dataWaiting;
              },
              width: 700,
            },
            {
              title: "Ngày cai sữa dự kiến",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return moment.unix(data.weaningDate).format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
              width: 700,
            },
            {
              title: "Tổng số con sơ sinh",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.totalPigletsBorn;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Số con dị tật",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.disabledPiglets;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Số con chết",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.deadPiglet;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Số con khô",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.driedPiglet;
                } else {
                  return "-";
                }
              },
              width: 700,
            },

            {
              title: "Số lợn con ",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.totalPigletsBorn;
                } else {
                  return "-";
                }
              },
              width: 700,
            },
            {
              title: "Trọng lượng TB(kg)",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NDE") {
                  return data.averageWeight;
                } else {
                  return "-";
                }
              },
              width: 700,
            },
          ];
          break;
        case "CVD":
          columnTabs = [
            {
              title: "Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 400,
            },
            {
              title: "Nguyên nhân ",
              dataIndex: ["pigStatId", "LON_NAI_CHET"],
              key: "pigStatId.LON_NAI_CHET",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NC") {
                  return data.reason;
                } else {
                  return "-";
                }
              },
              width: 250,
            },

            {
              title: "Ghi chú",
              dataIndex: ["pigStatId", "LON_NAI_CHET"],
              key: "pigStatId.LON_NAI_CHET",
              render(a: any) {
                const data = _.get(a, "[0][0]", "");
                if (data.actionCode === "NC") {
                  return data.note;
                } else {
                  return "-";
                }
              },
            },
          ];
          break;
        case "LD":
          columnTabs = [
            {
              title: "Mã Thẻ tai",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày sinh",
              dataIndex: "dob",
              key: "dob",
              width: 400,
              render(a: string) {
                return moment(a).format("DD/MM/YYYY");
              },
            },
            {
              title: "Ngày nhập",
              dataIndex: "dateImport",
              key: "dateImport",
              width: 250,
              render(a: string) {
                return moment(a).format("DD/MM/YYYY");
              },
            },

            {
              title: "Giống",
              dataIndex: ["additionInfo", "species"],
              key: "additionInfo.species",
              width: 250,
            },

            {
              title: "Trọng lượng(kg)",
              dataIndex: ["pigStatId", "weight"],
              key: "pigStatId.weight",
              width: 700,
            },
            {
              title: "Tình trạng",
              dataIndex: ["pigStatId", "stateId"],
              key: "pigStatId.stateId",
              width: 700,
              render(a: any) {
                return pigState.find((item: any) => item.value === a)?.label;
              },
            },
          ];
          break;
        case "LT":
          columnTabs = [
            {
              title: "Mã chuồng",
              dataIndex: ["herdId", "code"],
              key: "herdId.code",
              width: 200,
            },
            {
              title: "Tên chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 400,
            },
            {
              title: "Số con",
              dataIndex: "count",
              key: "count",
              width: 250,
            },

            {
              title: "Trọng lượng (kg)",
              dataIndex: "pigIds",
              key: "pigIds",
              width: 250,
              render: (row: any) => {
                if (!_.isUndefined(row)) {
                  const dataPig = row[0].pigStatId.weight;
                  return dataPig;
                }
              },
            },

            {
              title: "Tuần tuổi",
              dataIndex: "pigIds",
              key: "pigIds",
              width: 250,
              render(row: any) {
                if (!_.isUndefined(row)) {
                  const dataPig = row[0].dob;
                  const dataWaiting = Math.floor(
                    moment().diff(moment(dataPig), "days") / 7
                  );
                  return dataWaiting;
                }
              },
            },
          ];
      }
    } else {
      dataTabs = TAB_MANAGER_PIG_WHEN_TYPE_IS_MEAT;
      columnTabs = [
        {
          title: "Mã chuồng",
          dataIndex: "code",
          key: "code",
          width: 200,
        },
        {
          title: "Tên chuồng",
          dataIndex: "name",
          key: "name",
          width: 400,
        },
        {
          title: "Số con",
          dataIndex: "pigIds",
          key: "pigIds",
          width: 250,
          render: (_: any, record: any) => {
            return _.length;
          },
        },

        {
          title: "Trọng lượng TB(kg)",
          dataIndex: "count",
          key: "count",
          width: 250,
          render: (_: any, record: any) => {
            return "-";
          },
        },

        {
          title: "Tuần tuổi",
          dataIndex: "address",
          key: "address",
          width: 700,
          render(a: any) {
            return "-";
          },
        },
      ];
    }
  } else {
    if (typeOfHerd == "TTTH" || typeOfHerd == "TTN") {
      dataTabs = TAB_MANAGER_HISTORY;
      switch (keyTab) {
        case "PG":
          columnTabs = [
            {
              title: "Mã thẻ tai nái",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 250,
            },
            {
              title: "Số lứa đẻ",
              dataIndex: ["specialInfo", "numberOfBirths"],
              key: "specialInfo.numberOfBirths",
              width: 250,
            },

            {
              title: "Số con trung bình/lứa",
              dataIndex: ["pigStatId", "LON_NAI_DE"],
              key: "pigStatId.LON_NAI_DE",
              width: 500,
              render(a: any) {
                if (_.isUndefined(a)) {
                  return "-";
                } else {
                  const data = _.get(a, "[0][0]", "");
                  const totalResults = data?.totalPigletsBorn;
                  const slde = a?.specialInfo?.numberOfBirths;
                  if (slde > 0 && totalResults > 0) {
                    return Math.floor(totalResults / slde);
                  }
                }
              },
            },
            {
              title: "Hành động",
              dataIndex: "action",
              key: "action",
              width: 500,
              align: "left",
              render: (_: any, data: any) => {
                return (
                  <Space size="middle">
                    <a
                      onClick={() => {
                        const payload: any = {
                          open: true,
                          title: "Thông tin chi tiết lịch sử phối giống",
                          content: (
                            <ViewHistory record={data} keyTab={keyTab} />
                          ),
                          size: 1500,
                        };
                        dispatch(setModal(payload));
                      }}
                    >
                      Xem thông tin lịch sử phối giống
                    </a>
                  </Space>
                );
              },
            },
          ];
          //
          break;
        case "KTT":
          columnTabs = [
            {
              title: "Mã thẻ tai lợn đực",
              dataIndex: "code",
              key: "code",
              width: 200,
            },
            {
              title: "Ngày nhập",
              dataIndex: "dateImport",
              key: "dateImport",
              width: 400,
              render(a: any) {
                return moment(a).format("DD/MM/YYYY");
              },
            },

            {
              title: "Chuồng",
              dataIndex: ["herdId", "name"],
              key: "herdId.name",
              width: 700,
            },
            {
              title: "Hành động",

              width: 700,
              render: (record: any) => {
                return (
                  <Space size="middle">
                    <a
                      onClick={() => {
                        const payload: any = {
                          open: true,
                          title: "Thông tin chi tiết lịch sử phối giống",
                          content: (
                            <ViewHistory
                              record={record}
                              idUpdate={record?.id ?? false}
                            />
                          ),
                          size: 1500,
                        };
                        dispatch(setModal(payload));
                      }}
                    >
                      Xem thông tin lịch sử khai thác tinh
                    </a>
                  </Space>
                );
              },
            },
          ];
          break;
      }
    }
  }
  return (
    <>
      <Tabs
        onChange={handleChangeTabs}
        defaultActiveKey={tabListActive === "LIST" ? "HB" : "PG"}
        items={dataTabs.map((data: any) => {
          return {
            label: data?.label,
            key: data?.value,
          };
        })}
      />

      <Table
        style={{ marginLeft: "8px" }}
        className=""
        rowKey={"id"}
        columns={columnTabs}
        dataSource={
          typeOfHerd === "TTT"
            ? dataPigByTabStatus
            : _.get(dataPigByTabStatus, "results", [])
        }
        pagination={{
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 1),
          defaultPageSize: 3,
          showSizeChanger: true,
          pageSizeOptions: ["3", "10", "20", "50", "100"],
          total: _.get(dataPigByTabStatus, "totalResults", 1),
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            dispatch(
              setQuery({
                ...query,
                page: page,
                limit: pageSize,
              })
            );
          },
        }}
      />
    </>
  );
};

export default TabPig;
