import { Row, Col, Button, notification, Tabs } from "antd";
import CustomLabelValue from "pages/components/CustomLabelValue";
import {
  HistoryOutlined,
  UnorderedListOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ImportDataPig from "pages/PigFarm/ListPigFarm/importDataPig";
import { ModalInfoType, PayloadType } from "type";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import _ from "lodash";
import queryString from "query-string";
import { memo, useEffect, useState } from "react";
import { getStatisticals } from "features/statisticalSlice";

import TabPig from "./TabPig";
import ReworkSynthe from "./ReworkSynthe";
function tabContent(data: any) {
  const dataHerd = _.get(data, "record", {});
  const typeOfHerd = _.get(data, "record.typeId.code", "");
  const dataTabList = [
    {
      label: "Danh sách lợn trong trại",
      key: "LIST",
    },
    {
      label: "Lịch sử",
      key: "HISTORY",
    },
  ];

  const dataTabListTTT = [
    {
      label: "Danh sách lợn trong trại",
      key: "LIST",
    },
  ];

  const handleChangeTabList = (key: string) => {
    setKeyTabList(key);
  };

  const [keyTabList, setKeyTabList] = useState<any>("LIST");
  const dataActiverHerd = _.get(data, "dataActiverHerd", {});
  const [dataPigs, setDataPigs] = useState<any>({});
  const dispatch = useDispatch();

  // call api lấy thông tin về lợn của trại
  function handleGetHerdByUserId(herdId: any) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          herdId: herdId,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(herds) {
          setDataPigs(herds?.result);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getStatisticals(payload));
  }

  useEffect(() => {
    handleGetHerdByUserId(dataHerd.id);
  }, []);

  return (
    <>
      <Row
        id="2"
        style={{
          paddingTop: 16,
          paddingLeft: 20,
          paddingRight: 20,
          background: "rgba(242, 242, 242, 0.50)",
        }}
      >
        <Col span={12}>
          <CustomLabelValue
            hasMarginleft={false}
            alignX={120}
            label="Mã trang trại"
            value={_.get(dataHerd, "code", "")}
          />
          <CustomLabelValue
            hasMarginleft={false}
            alignX={120}
            label="Tên trang trại "
            value={_.get(dataHerd, "name", "")}
          />

          <CustomLabelValue
            hasMarginleft={false}
            alignX={120}
            label="Loại trang trại"
            value={_.get(dataHerd, "typeId.name", "")}
          />
          <CustomLabelValue
            hasMarginleft={false}
            alignX={120}
            colorStatus={_.get(dataActiverHerd, "status", "")}
            label="Trạng thái: "
            value={
              _.get(dataActiverHerd, "status", "") === "active"
                ? "Đang hoạt động"
                : "Đóng"
            }
          />
        </Col>
        <Col span={12}>
          <CustomLabelValue
            hasMarginleft={false}
            alignX={120}
            label="Tổng đàn"
            value={
              _.get(dataPigs, "TONG_NAI", []) +
              " Nái; " +
              _.get(dataPigs, "TONG_DUC", []) +
              " Đực; " +
              _.get(dataPigs, "TONG_CON", []) +
              " Lợn con; " +
              _.get(dataPigs, "TONG_THIT", []) +
              " Thịt"
            }
          />
          <CustomLabelValue
            hasMarginleft={false}
            alignX={120}
            label="Số chuồng: "
            value={_.get(dataHerd, "childrent", []).length}
          />

          <CustomLabelValue
            hasMarginleft={false}
            alignX={120}
            label="Địa chỉ hiện tại"
            value={_.get(dataActiverHerd, "address", "")}
          />
          {_.get(dataActiverHerd, "status", "") === "active" ? (
            <Row>
              <Col
                lg={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold " }}>Cập nhật dữ liệu:</div>
              </Col>
              <Col lg={20}>
                &nbsp;
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  onClick={() => {
                    const payload: ModalInfoType = {
                      open: true,
                      title: "Nhập danh sách lợn",
                      content: <ImportDataPig data={data} type={"ImportPig"} />,
                      dataUpdate: "",
                      size: 350,
                    };
                    dispatch(setModal(payload));
                  }}
                >
                  <UnorderedListOutlined />
                  Nhập DS lợn
                </Button>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  onClick={() => {
                    const payload: ModalInfoType = {
                      open: true,
                      title: "Nhập lịch sử lợn",
                      content: (
                        <ImportDataPig data={data} type={"ImportHistory"} />
                      ),
                      dataUpdate: "",
                      size: 350,
                    };
                    dispatch(setModal(payload));
                  }}
                >
                  <HistoryOutlined />
                  Nhập lịch sử lợn
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    const payload: ModalInfoType = {
                      open: true,
                      title: "Chọn ngày chạy lại",
                      content: (
                        <ReworkSynthe
                          data={{
                            herdId: dataHerd.id,
                          }}
                        />
                      ),
                      dataUpdate: "",
                      size: 350,
                    };
                    dispatch(setModal(payload));
                  }}
                >
                  <SyncOutlined />
                  Chạy lại thống kê tổng hơp
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <div>
        <Tabs
          onChange={handleChangeTabList}
          defaultActiveKey={`${keyTabList}`}
          items={typeOfHerd === "TTT" ? dataTabListTTT : dataTabList}
        />
        <TabPig
          typeOfHerd={typeOfHerd}
          data={data}
          keyTabList={keyTabList}
          setKeyTabList={setKeyTabList}
        />
      </div>
    </>
  );
}

export default memo(tabContent);
