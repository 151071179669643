import {
  Button,
  Form,
  Typography,
  Select,
  Col,
  Row,
  notification,
  Input,
  Popconfirm,
  Table,
  Space,
  Radio,
} from "antd";
import { CloseCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import React from "react";
import type { ColumnsType } from "antd/es/table";
const { Title } = Typography;
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { PayloadType, Role, SelectOptionType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import _ from "lodash";
import queryString from "query-string";
import { getHerdTypes } from "features/herdTypeSlice";
import { getSharedData1s, getSharedDatas } from "features/sharedDataSlice";
import dataProvince from "dataAddress/province.json";
import dataDistrict from "dataAddress/districts.json";
import { LOAI_CHUONG } from "const";

import { getTypeGroup1s, getTypeGroups } from "features/typeGroupSlice";
import { createHerd, getHerds } from "features/herdSlice";

function CUHerd({ record, getHerdByUser }: any) {
  const [form] = useForm();
  const [formRight] = useForm();
  const dispatch = useDispatch();
  const { open } = useSelector((state: any) => state.modalReducer);
  const [listHerdType, setListHerdType] = useState<any[]>([]);
  const [addDatas, setAddDatas] = useState<any[]>([]);
  const [listFarmScale, setListFarmScale] = useState<SelectOptionType[]>([]);
  const [listBarnType, setListBarnType] = useState<SelectOptionType[]>([]);
  const [districtList, setDistrictList] = useState<SelectOptionType[]>([]);

  // lấy danh sách loại chuồng từ shareData
  const getBarnTypes = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(herdType) {
          const results = _.get(herdType, "results", []);
          if (!!results && Array.isArray(results)) {
            setListHerdType(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách loại trại! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerdTypes(payload));
  };

  useEffect(() => {
    getBarnTypes();
  }, []);

  // Danh sách option fields Tỉnh
  const optionProinces = dataProvince.map((item: any) => {
    return {
      label: item.province_name,
      value: item.province_id,
    };
  });

  // Hàm lấy danh sách huyện theo tỉnh
  const handleChangeProvince = (value: any) => {
    const dataDistrictByProvince = dataDistrict.filter((item: any) => {
      return item.province_id === value;
    });
    const optionDistrict = dataDistrictByProvince.map((data) => {
      return {
        label: data.district_name,
        value: data.district_name,
      };
    });
    setDistrictList(optionDistrict);
  };

  // lấy danh sách loại trại
  const getListHerdType = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(herdType) {
          const results = _.get(herdType, "results", []);
          if (!!results && Array.isArray(results)) {
            setListHerdType(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách loại trại! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerdTypes(payload));
  };
  useEffect(() => {
    getListHerdType();
  }, []);

  // lấy danh sách quy mô trang trại
  const getFieldFarmScale = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        code: "FARM_SCALE",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          const typeGroupId = _.get(results, "[0].id", "");
          const payload: PayloadType = {
            query: queryString.stringify({
              page: 1,
              limit: 999,
              typeGroupId: typeGroupId,
            }),
            skipUpdateReducer: true,
            callback: {
              success(listScales) {
                const results = _.get(listScales, "results", []);
                if (!!results && Array.isArray(results)) {
                  setListFarmScale(
                    results.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })
                  );
                }
              },
              failed(errorMessage) {},
            },
          };
          dispatch(getSharedData1s(payload));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getTypeGroup1s(payload));
  };
  useEffect(() => {
    getFieldFarmScale();
  }, []);

  // lấy danh sách loại chuồng
  const getListBarnType = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        code: "BARN_TYPE",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          const typeGroupId = _.get(results, "[0].id", "");
          const payload: PayloadType = {
            query: queryString.stringify({
              page: 1,
              limit: 999,
              typeGroupId: typeGroupId,
            }),
            skipUpdateReducer: true,
            callback: {
              success(listScales) {
                const results = _.get(listScales, "results", []);
                if (!!results && Array.isArray(results)) {
                  setListBarnType(
                    results.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })
                  );
                }
              },
              failed(errorMessage) {},
            },
          };
          dispatch(getSharedDatas(payload));
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getTypeGroups(payload));
  };
  useEffect(() => {
    getListBarnType();
  }, []);

  /// Form thông tin tạo trại bên trái
  const formLeftSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập tên trang trại!"),
    typeId: yup.string().required("Vui lòng chọn loại trang trại!"),
    barnType: yup.string().required("Vui lòng chọn mô hình chuồng!"),
    farmScaleId: yup.string().required("Vui lòng chọn quy mô của trang trại!"),
    province: yup.string().required("Vui lòng chọn tỉnh!"),
    district: yup.string().required("Vui lòng chọn quận/huyện!"),
    nameForPig: yup.string().required("Vui lòng chọn tên gọi!"),
  });

  // form thông tin tạo chuồng cho trại
  const formRightSchema = yup.object().shape({
    name: yup.string().required("Vui lòng nhập tên chuồng"),
    typeId: yup.string().required("Vui lòng chọn loại chuồng!"),
  });

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [record, open]);

  const leftYupSync = [getYupSync(formLeftSchema)];
  const rightYupSync = [getYupSync(formRightSchema)];

  function handleSubmit() {
    const valueHerd = form.getFieldsValue([
      "name",
      "typeId",
      "barnType",
      "farmScaleId",
      "province",
      "district",
      "nameForPig",
    ]);

    handleCreateHerd(valueHerd, addDatas);
  }

  /*
   * Hàm tạo chuồng sau khi tạo trại thành công
   * @params: herdId
   * @params: data
   */
  const createBarnByHerdId = async (herdId: string, data: any) => {
    const payload1: PayloadType = {
      body: {
        parent: herdId,
        level: 1,
        ...data,
      },
      params: _.get(record, "id", ""),
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới chuồng thành công!",
          });
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể tạo mới chuồng !",
            description: errorMessage,
          });
        },
      },
    };
    await dispatch(createHerd(payload1));
  };

  // Hàm gộp data các chuồng được thành 1 mảng để create
  function handleCreateDetailProcess() {
    const values = formRight.getFieldsValue(["name", "typeId"]);
    setAddDatas([...addDatas, values]);
    formRight.setFieldValue("name", null);
    formRight.setFieldValue("typeId", null);
  }

  const handleCreateHerd = async (values: any, addDatas: any) => {
    const payload: PayloadType = {
      body: {
        ...values,
        level: 0,
      },
      params: _.get(record, "id", ""),
      callback: {
        success(resultHerdCreate) {
          notification.success({
            message: "Tạo mới trại người dùng thành công!",
          });

          addDatas.forEach((item: any) => {
            createBarnByHerdId(_.get(resultHerdCreate, "id", ""), item);
          });
          getHerdByUser();
          setAddDatas([]);
          closeModal(dispatch, setModal);
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể tạo mới trại người dùng !",
            description: errorMessage,
          });
        },
      },
    };
    await dispatch(createHerd(payload));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Tên chuồng",
      dataIndex: "name",
      key: "name",
      width: "55%",
    },
    {
      title: "Loại chuồng",
      dataIndex: "typeId",
      key: "typeId",
      width: "40%",
      render: (VALUES, ITEM) => {
        const x = _.find(listBarnType, function (o) {
          return o.value === VALUES;
        });
        return <div>{_.get(x, "label", "")}</div>;
      },
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "5%",
      align: "left",
      render: (val, record: any, index: any) => {
        return (
          <Space size="middle">
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                setAddDatas([
                  ...addDatas.slice(0, index),
                  ...addDatas.slice(index + 1),
                ]);
              }}
            >
              <a style={{ color: "red" }}>
                {React.createElement(CloseCircleOutlined)}
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const initValues = {
    code: "",
    name: "",
    typeId: undefined,
    farmName: "",
    barnType: undefined,
    farmScaleId: undefined,
    province: undefined,
    district: undefined,
    nameForPig: "Heo",
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
    setAddDatas([]);
  }, []);

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div
        className="ant-modal-body"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Row style={{ width: "45%" }}>
          <Col className="gutter-row" span={24}>
            <Title level={5} style={{ paddingBottom: "12px" }}>
              Thông tin trại
            </Title>

            <Form.Item
              required
              label={"Tên trang trại"}
              name={"name"}
              rules={leftYupSync}
            >
              <Input placeholder="Nhập tên trang trại" />
            </Form.Item>
            <Form.Item
              required
              label={"Phân loại trang trại"}
              name={"typeId"}
              rules={leftYupSync}
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Chọn phân loại trang trại"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listHerdType}
              />
            </Form.Item>

            <Form.Item
              required
              rules={leftYupSync}
              label={"Mô hình chuồng"}
              name={"barnType"}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                allowClear
                placeholder="Chọn loại chuồng "
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={LOAI_CHUONG}
              />
            </Form.Item>

            <Form.Item
              required
              label={"Quy mô trang trại"}
              name={"farmScaleId"}
              rules={leftYupSync}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Chọn quy mô"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                allowClear
                options={listFarmScale}
              />
            </Form.Item>

            <Form.Item
              required
              label={"Tỉnh"}
              name={"province"}
              rules={leftYupSync}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Chọn tỉnh"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                onChange={(value: any) => {
                  handleChangeProvince(value);
                }}
                allowClear
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={optionProinces}
              />
            </Form.Item>

            <Form.Item
              required
              label={"Quận/Huyện"}
              name={"district"}
              rules={leftYupSync}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Chọn quận/huyện"
                optionFilterProp="children"
                allowClear
                disabled={districtList.length === 0 ? true : false}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={districtList}
              />
            </Form.Item>

            <Form.Item
              required
              label={"Thiết lập tên gọi"}
              name="nameForPig"
              rules={leftYupSync}
            >
              <Radio.Group>
                <Radio value={"Heo"}>Heo</Radio>
                <Radio value={"Lợn"}>Lợn</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "55%" }}>
          <Col className="gutter-row" span={24} style={{ paddingLeft: "17px" }}>
            <Title level={5} style={{ paddingBottom: "12px" }}>
              Danh sách chuồng
            </Title>

            <Table
              columns={columns}
              rowKey={"id"}
              dataSource={addDatas}
              pagination={false}
              scroll={{ y: 110 }}
            />
            <Row
              style={{
                paddingTop: 20,
                borderWidth: 2,
                borderColor: "red",
                border: 1,
              }}
            >
              <Col className="gutter-row" span={24}>
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                  form={formRight}
                  onFinish={() => {
                    handleCreateDetailProcess();
                  }}
                  autoComplete="off"
                >
                  <Form.Item
                    style={{ width: "55%", marginRight: 15 }}
                    required
                    name={"name"}
                    rules={rightYupSync}
                  >
                    <Input placeholder="Nhập tên chuồng " />
                  </Form.Item>

                  <Form.Item
                    style={{ width: "40%", marginRight: 15 }}
                    required
                    name={"typeId"}
                    rules={rightYupSync}
                  >
                    <Select
                      showSearch
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Chọn loại chuồng"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={listBarnType}
                    />
                  </Form.Item>

                  <Form.Item style={{ width: "5%", marginLeft: 15 }}>
                    <Button
                      onClick={() => {
                        formRight.submit();
                      }}
                    >
                      {React.createElement(PlusSquareOutlined)}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
        >
          {"Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUHerd;
