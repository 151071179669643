import { getDistrictsSuccess } from "features/districtSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET_PROVINCES } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getDistricts(data: ActionType): any {
  const { callback, body, query } = data.payload;
  const url = `${ServiceURL.district}?${query ?? ""}`;
  try {
    const res = yield call(GET_PROVINCES, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      yield put(getDistrictsSuccess(res));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}

export default function* districtSaga() {
  yield takeLatest("districtSlice/getDistricts", getDistricts);
}
