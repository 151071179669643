/**
 * Tab Nhóm khác
 */
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Layout,
  Typography,
  notification,
  Tabs,
  Space,
  Table,
} from "antd";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
const { Content } = Layout;
import { useHistory } from "react-router";
import { MangerHerdType, ModalInfoType, PayloadType } from "type";
import { useDispatch, useSelector } from "react-redux";
import _, { values } from "lodash";
import queryString from "query-string";
import { getHerds, getPigByUserId } from "features/herdSlice";
const { Title } = Typography;
import TabContent from "./tabContent";

function RPigFarm(dataView: any) {
  const dataUser = dataView?.history?.location.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useSelector((state: any) => state.subscriberReducer);
  const [listHerds, setListHerd] = useState<string[]>([]);
  const [listNumberPigs, setListNumberPigs] = useState<string[]>([]);

  //Lấy thông tin trại theo userId
  function handleGetHerdByUserId(record: any) {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          level: 0,
          userId: _.get(record, "id", ""),
          populate: "farmScaleId, herdStatId, typeId, childrent,pigIds",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(herds) {
          setListHerd(herds.results);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerds(payload));
  }
  useEffect(() => {
    handleGetHerdByUserId(dataUser);
  }, [query]);

  //Lấy thông tin lợn từng loại theo userId
  function handleGetNumberPigByUserId(record: any) {
    const payload: PayloadType = {
      params: _.get(record, "id", ""),
      callback: {
        success(herds) {
          console.log("HERD", herds);
          setListNumberPigs(herds);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPigByUserId(payload));
  }
  useEffect(() => {
    handleGetNumberPigByUserId(dataUser);
  }, [query]);

  /// danh sách các trại thành viên

  const tabHerds = listHerds.map((dataHerd, index) => {
    return {
      key: `${index + 1}`,
      label: `Trại  ${index + 1}`,
      children: <TabContent record={dataHerd} dataActiverHerd={dataUser} />,
    };
  });
  function calculateTotal(data: any[], key: string) {
    return data.reduce((total: number, item: any) => {
      if (item.level === 0) {
        return total + item.result[key];
      }
      return total;
    }, 0);
  }

  const tabs: any = [
    {
      key: "0",
      label: "Tổng quan đàn",
      children: (
        <Row
          style={{
            paddingTop: 16,
            paddingLeft: 20,
            paddingRight: 20,
            background: "rgba(242, 242, 242, 0.50)",
          }}
        >
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tên chủ trại"
              value={_.get(dataUser, "name", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Số trại"
              value={listHerds.length}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Số điện thoại"
              value={_.get(dataUser, "phone", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Địa chỉ: "
              value={_.get(dataUser, "address", "")}
            />
          </Col>
          <Col span={12}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tổng số lợn nái"
              value={calculateTotal(listNumberPigs, "TONG_NAI")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tổng số lợn đực"
              value={calculateTotal(listNumberPigs, "TONG_DUC")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tổng số lợn con"
              value={calculateTotal(listNumberPigs, "TONG_CON")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={120}
              label="Tổng số lợn thịt "
              value={calculateTotal(listNumberPigs, "TONG_THIT")}
            />
          </Col>
        </Row>
      ),
    },
  ];

  const dataHerds = tabs.concat(tabHerds);

  // Giao diện danh sách trại
  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 20,
        marginTop: 16,
        marginRight: 20,
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <div
          className="space-between "
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="flex space-between" style={{ alignItems: "center" }}>
            <a
              style={{ color: "#000" }}
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowLeftOutlined />
            </a>
            <Title
              style={{
                marginBottom: 0,
                paddingLeft: 30,
                paddingTop: 16,
                paddingBottom: 16,
              }}
              level={4}
            >
              Chi tiết thông tin trại
            </Title>
          </div>
        </div>

        <Tabs defaultActiveKey="1" items={dataHerds} />
      </Space>
    </Content>
  );
}

export default RPigFarm;
